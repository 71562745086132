import React, { useState, useEffect } from "react";
// import { API_URL } from "../../../constants";
// import { useSelector } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import axios from "axios";
import QrReader from "react-qr-reader";
// import { Link } from "react-router-dom";

export const initialValues = {};

export function QrCodeDialog(props) {
  const { entity, open, onClose } = props;
  const [msg, setMsg] = useState("");

  const handleScan = (data) => {
    if (data && entity) {
      axios
        .get(
          "/api/offeringScheduleSessions/" +
            entity.id +
            "/attendance/scan/" +
            data
        )
        .then(function(response) {
          let data = response.data;
          if (data) {
            setMsg("Confirm Attendance");
          }
        })
        .catch((error) => {
          let err = error.response.data;
          setMsg(err.details);
        });
    }
  };

  const handleError = (err) => {
    setMsg("Please allow your device to use camera");
  };

  return (
    <Dialog
      open={open}
      keepMounted
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">
        <Typography variant="h5">Take Attendance ({entity.class})</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid>
          {open && (
            <QrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: 320, height: 320 }}
              facingMode="environment"
            />
          )}
          <Typography variant="h5">{msg}</Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            onClose();
            setMsg("");
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

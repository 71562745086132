import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";

export default function(props) {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing client "${entity.username}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/customerUsers/${entity.id}`)
      .then(function(response) {
        //notify(`Client "${entity.username}" removed`, "success");
        if (response.data == true) {
          notify(`Client "${entity.username}" removed`, "success");
        } else {
          notify(
            `Client "${entity.username}" has purchased package plan or has enrolled class. Cannot delete this record.`,
            "error"
          );
        }
        onSuccess();
      })
      .catch((error) => {
        if (error.response !== undefined) {
          notifyApiError(
            error.response?.status,
            {
              403: {
                message: `Access denied to remove client "${entity.username}"`,
                variant: "error",
              },
            },
            {
              401: {
                message: `Unable to remove client "${entity.username}"`,
                variant: "error",
              },
            }
          );
        }
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Client?"
      message={
        <React.Fragment>
          Client <b>{entity.username}</b> is going to be deleted. You will not
          be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
}

import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import _ from "lodash";
import LabelledStatusBullet from "../../../components/LabelledStatusBullet";

const DisplayDialog = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    const truncatedName = _.truncate(entity.name, {
      length: 30,
      separator: " ",
    });

    axios
      .get(`/api/offeringCategories/${entity.id}`)
      .then((response) => {
        active && setDetailedEntity(response.data);
      })
      .catch((error) => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch class category "${truncatedName}"`,
                variant: "error",
              },
            },
            {
              message: `Unable to fetch class category "${truncatedName}"`,
              variant: "error",
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Class Category Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{detailedEntity.name}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Instructor</TableCell>
            <TableCell>{entity.showInstructorOption ? <LabelledStatusBullet color="success" label="Enabled" /> : <LabelledStatusBullet color="default" label="Disabled" />}</TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;

import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import WizardStepCard from "./WizardStepCard";
import CustomerUserSearchForm from "./CustomerUserSearchForm";
import SelectPurchasedPackagePlanForm from "./SelectPurchasedPackagePlanForm";
import CustomerUserSelectionForm from "./CustomerUserSelectionForm";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import FormDialog from "../../../components/dialogs/FormDialog";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles({
  form: {
    width: 836,
  },
});

export default function(props) {
  const {
    open,
    onClose,
    title,
    loading,
    onSearchSubmit,
    onCustomerUserSelect,
    candidates,
    selectedCustomerUser,
    purchasedPackagePlans,
    onPurchasedPackagePlanSelect,
    selectedPurchasedPackagePlan,
    onSubmit,
    onReset,
    dirty,
    isValid,
    isSubmitting,
    searchText,
    onSearchTextChange,
    needInstructor,
    handleNeedInstructorChange,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <FormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
      onSubmit={onSubmit}
      onReset={onReset}
      submitButtonDisabled={!dirty || !isValid || isSubmitting}
      resetButtonDisabled={(!dirty && isValid) || isSubmitting}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <WizardStepCard order={1} title="Search Client">
          <CustomerUserSearchForm
            searchText={searchText}
            onSearchTextChange={onSearchTextChange}
            onSubmit={onSearchSubmit}
          />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={2} title="Select Client">
          <CustomerUserSelectionForm
            candidates={candidates}
            onSelect={onCustomerUserSelect}
            selected={selectedCustomerUser}
          />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        <WizardStepCard order={3} title="Select Package Plan">
          <SelectPurchasedPackagePlanForm
            purchasedPackagePlans={purchasedPackagePlans}
            onSelect={onPurchasedPackagePlanSelect}
            selected={selectedPurchasedPackagePlan}
          />
        </WizardStepCard>
        <Divider orientation="horizontal" />
        {/* <WizardStepCard order={4} title="Instructor Option">
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={needInstructor} onChange={handleNeedInstructorChange} name="needInstructor" />}
                label="Need Instructor"
                value={needInstructor}
              />
            </FormGroup>
          </FormControl>
        </WizardStepCard> */}
      </MuiPickersUtilsProvider>
    </FormDialog>
  );
}

import React, { useEffect, useState } from "react";
import { FormLabel, makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {
  GENDER_OPTIONS,
  STAFF_USER_ROLE_OPTIONS,
} from "../../../constants/app.constant";
import PasswordField from "../../../components/inputs/PasswordField";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikRadioGroup from "../../../components/inputs/FormikRadioGroup";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import { coreCreateParams } from "components/CoreCreateParams";
import moment from "moment";
import FormikCheckboxField from "components/inputs/FormikCheckboxField";
import axios from "axios";
import { useFormikContext } from "formik";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    ...theme.typography.h5,
  },
}));

export default function(props) {
  const { open, onClose, title, loading, edit, ...rest } = props;

  const [branchesOptions, setBranchesOptions] = useState([]);
  const [checkedBranches, setCheckedBranches] = useState([]);
  const { values, setFieldValue } = useFormikContext();

  const classes = useStyles();

  const handleStoreBranchesChange = (event) => {
    if (event.target.checked) {
      if (checkedBranches.includes(event.target.value)) {
        return checkedBranches;
      } else {
        setCheckedBranches([...checkedBranches, event.target.value]);
        return [...checkedBranches, event.target.value];
      }
    } else {
      const newValues = checkedBranches.filter((p) => p !== event.target.value);
      setCheckedBranches(newValues);
      return newValues;
    }
  };

  useEffect(() => {
    if (open && !loading) {
      axios
        .get("/api/storeBranches/variants/references")
        .then((res) => {
          let data = res.data;
          setBranchesOptions(data);
        })
        .catch((err) => {
          //console.log(err);
        });

      setCheckedBranches(values.storeBranchesId);
    }
  }, [open, loading]);

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              label="Username"
              name="username"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Password (Not less than 8 characters)"
              name="password"
              variant="outlined"
              margin="dense"
              component={PasswordField}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              required
              fullWidth
              label="First Name"
              name="firstName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              // required
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          {/*<Grid item md={3} xs={12}>
            <FormikTextField
              fullWidth
              label="Middle Name"
              name="middleName"
              variant="outlined"
              margin="dense"
            />
  </Grid>*/}
          <Grid item md={4} xs={12}>
            <FormikAutocomplete
              required
              name="gender"
              margin="dense"
              autoHighlight
              options={GENDER_OPTIONS}
              getOptionLabel={(option) => option && (option.label || "")}
              TextFieldProps={{
                label: "Gender",
                variant: "outlined",
                fullWidth: true,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              autoOk
              disableFuture
              name="birthday"
              inputVariant="outlined"
              format="DD/MM/YYYY"
              placeholder="DD/MM/YYYY"
              margin="dense"
              fullWidth
              label="Birthday"
              InputAdornmentProps={{ position: "start" }}
              KeyboardButtonProps={{ size: "small" }}
              required
            />
          </Grid>
          {/*<Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Email"
              name="email"
              variant="outlined"
              margin="dense"
            />
            </Grid>*/}
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Mobile Phone"
              name="mobilePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Emergency Contact"
              name="emergencyContact"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Emergency Relationship"
              name="emergencyRelationship"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          {/*<Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Home Phone"
              name="homePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Work Phone"
              name="workPhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              label="Street"
              name="street"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="State or Province"
              name="stateOrProvince"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Country"
              name="country"
              variant="outlined"
              margin="dense"
            />
          </Grid>*/}
          <Grid item md={12} xs={12}>
            {/* <FormikStoreBranchSelect
              //required
              //multiple
              //filterSelectedOptions
              fullWidth
              name="storeBranchesId"
              label="Store Branches"
              margin="dense"
              variant="outlined"
            /> */}
            <FormLabel component="legend" className={classes.sectionLabel}>
              Work Location
            </FormLabel>
            {branchesOptions.map((option) => (
              <FormikCheckboxField
                label={option.name}
                name={option.id}
                value={option.id}
                size="small"
                checked={checkedBranches.includes(option.id.toString())}
                onChange={(event) =>
                  setFieldValue(
                    "storeBranchesId",
                    handleStoreBranchesChange(event)
                  )
                }
              />
            ))}
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikRadioGroup
              required
              name="role"
              label="Role"
              options={STAFF_USER_ROLE_OPTIONS}
              FormLabelProps={{
                className: classes.sectionLabel,
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="instructor"
              label="Instructor"
              description="Indicate if this user is an instructor"
              FormLabelProps={{
                className: classes.sectionLabel,
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, "At least 3 characters long")
    .max(100, "No more than 100 characters long")
    .matches(
      /^[a-zA-Z0-9_.]*$/,
      "Only alphanumeric characters, dots and underscores allowed"
    )
    .required("Required"),
  password: Yup.string()
    .min(3, "At least 3 characters long")
    .max(20, "No more than 20 characters long"),
  firstName: Yup.string()
    .max(50, "No more than 50 characters long")
    .required("Required"),
  lastName: Yup.string().max(50, "No more than 50 characters long"),
  // .required("Required"),
  // middleName: Yup.string().max(50, "No more than 50 characters long"),
  // email: Yup.string().email("Invalid email"),
  gender: Yup.string()
    .required("Required")
    .nullable(),
  birthday: Yup.date()
    .nullable()
    .required("Required"),
  mobilePhone: Yup.string()
    .min(8, "No less than 8 characters long")
    .max(8, "No more than 8 characters long")
    .matches(/^[0-9]*$/, "Please enter correct phone number"),
  emergencyContact: Yup.string().max(50, "No more than 50 characters long"),
  emergencyRelationship: Yup.string().max(
    50,
    "No more than 50 characters long"
  ),
  role: Yup.string().required("Required"),
  storeBranchesId: Yup.array().nullable(),
});

export const initialValues = {
  //id: null,
  username: "",
  password: "",
  firstName: "",
  lastName: "",
  //middleName: "",
  gender: null,
  birthday: null,
  //email: "",
  role: "10001",
  mobilePhone: "",
  //homePhone: "",
  //workPhone: "",
  instructor: false,
  //street: "",
  //city: "",
  //stateOrProvince: "",
  //country: "",
  //workLocation: "",
  emergencyContact: "",
  emergencyRelationship: "",
  storeBranchesId: [],
};

export const createParams = (values) => {
  const editableField = [
    "username",
    "password",
    "firstName",
    "lastName",
    "gender",
    "birthday",
    "mobilePhone",
    "emergencyContact",
    "emergencyRelationship",
    "instructor",
    "role",
    "storeBranchesId",
  ];
  values.gender = values.gender.value;

  const params = coreCreateParams(editableField, values);
  params.birthday = moment(params.birthday).format("YYYY-MM-DD");
  /*
  const params = new URLSearchParams();

  values.username && params.append("username", values.username);
  values.firstName && params.append("firstName", values.firstName);
  values.lastName && params.append("lastName", values.lastName);
  values.middleName && params.append("middleName", values.middleName);
  values.email && params.append("email", values.email);
  values.mobilePhone && params.append("mobilePhone", values.mobilePhone);
  values.homePhone && params.append("homePhone", values.homePhone);
  values.workPhone && params.append("workPhone", values.workPhone);
  values.instructor && params.append("instructor", values.instructor);
  values.street && params.append("street", values.street);
  values.city && params.append("city", values.city);
  values.stateOrProvince &&
    params.append("stateOrProvince", values.stateOrProvince);
  values.country && params.append("country", values.country);
  values.role && params.append("role", values.role);
  values.birthday &&
    params.append("birthday", values.birthday.format("YYYY-MM-DD"));
  values.password && params.append("password", values.password);
  values.workLocation && params.append("workLocation", values.workLocation.id);
  if (values.gender && values.gender.value) {
    params.append("gender", values.gender.value);
  }
*/
  return params;
};

export const ADD_PERSONAL_SETTINGS = "PERSONAL_SETTINGS/ADD";
export const REMOVE_PERSONAL_SETTINGS = "PERSONAL_SETTINGS/REMOVE";

export function addPersonalSettings(personalSettings) {
  return {
    type: ADD_PERSONAL_SETTINGS,
    payload: personalSettings,
  };
}

export function removePersonalSettings() {
  return {
    type: REMOVE_PERSONAL_SETTINGS,
  };
}

import axios from "axios";
import securityService from "./security.service";
import axiosConfig from "../config/axios.config";
import store from "../stores";
import { sessionStatusInitial } from "../security/SecurityFilter/actions/sessionStatus.action";

class oauth2Service {
  requestToken = (username, password, recaptcha) => {
    // let params = new URLSearchParams();
    // params.append("username", username);
    // params.append("password", password);
    // params.append("grant_type", "password");
    // params.append("scope", "api");
    //params.append("recaptcha", recaptcha);

    return new Promise((resolve, reject) => {
      // Reject with the response
      axios
        .post(
          "/api/auth/login",
          {
            username: username,
            password: password,
            recaptcha: recaptcha,
          },
          {
            auth: {
              username: "booking",
              password: "booking",
            },
          }
        )
        .then((response) => {
          if (this.isTokenResponse(response)) {
            let accessToken = response.data.accessToken;

            securityService.setAccessToken(accessToken);
            axiosConfig.setAuthorizationHeader(accessToken);
            resolve(response.data);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          //console.log(error.response);
          reject(error.response);
        });
    });
  };

  isTokenResponse = (response) => {
    let data = response.data;

    return data && typeof data === "object" && "accessToken" in data;
  };

  isErrorResponse = (response) => {
    return (
      response &&
      response.data &&
      typeof response.data === "object" &&
      "error" in response.data &&
      "error_description" in response.data
    );
  };

  handleErrorResponse = (response) => {
    let status = response.status;

    if (status === 401) {
      // Handle unauthenticated error
      securityService.removeAccessToken();
      axiosConfig.removeAuthorizationHeader();
      store.dispatch(sessionStatusInitial());
    }
  };
}

export default new oauth2Service();

import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import { useFormikContext } from "formik";
import { CLASS_SESSIONS_STATUS } from "constants/app.constant";
import FormikRadioGroup from "components/inputs/FormikRadioGroup";
import { coreCreateParams } from "components/CoreCreateParams";

const useStyles = makeStyles((theme) => ({
  form: {
    width: 800,
  },
  editorLabel: {
    ...theme.typography.h5,
  },
}));

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
    >
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <FormikRadioGroup
            required
            name="status"
            label="Status"
            options={CLASS_SESSIONS_STATUS}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <FormikTextField
            fullWidth
            name="remarks"
            label="Remarks"
            variant="outlined"
            margin="dense"
          />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({});

export const initialValues = {
  status: "PENDING",
  remarks: "",
};

export const createParams = (values) => {
  const editableField = ["status", "remarks"];

  const params = coreCreateParams(editableField, values);

  return params;
};

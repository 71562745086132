import React from "react";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    position: "relative",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    whiteSpace: "nowrap",
  },
  columnAction: {
    minWidth: 120,
  },
  packageTitle: {
    fontWeight: "bold",
    margin: "5px",
  },
  textCenter: {
    textAlign: "center",
  },
}));

function LatestPackageDialog(props) {
  const { open, onClose, entity, ...rest } = props;
  const [loading, setLoading] = React.useState(false);
  const [latestPackage, setLatestPackage] = React.useState(null);

  const notifyApiError = useApiNotification();

  const classes = useStyles();

  React.useEffect(() => {
    if (open) {
      setLoading(true);

      let active = true;

      if (!entity) return;

      axios
        .get(`/api/purchasedPackagePlans/customerUsers/${entity.id}`, {})
        .then((response) => {
          var result = response.data["content"];
          var latest = {};
          for (var i = 0; i < result.length; i++)
            if (result[i].id == entity.latestExpirationPackagePlan.id) {
              latest = result[i];
            }

          var mainBranch = [];
          var additionalBranch = [];
          var totalBranch = [];

          latest.items.map((item) => {
            if (item.totalTicketCount > 0) {
              var tempMain = item;
              tempMain.calculatedCount = parseInt(
                item.usedPurchasedPackagePlanItem,
                10
              );
              mainBranch.push(item);
            } else {
              additionalBranch.push(item);
            }
          });

          latest.mainBranch = mainBranch;
          latest.additionalBranch = additionalBranch;
          if (additionalBranch.length > 0) {
            for (var i = 0; i < latest.items.length; i++)
              if (latest.items[i].totalTicketCount > 0)
                totalBranch.push(latest.items[i]);
          }
          latest.totalBranch = totalBranch;
          setLatestPackage(latest);
        })
        .catch((error) => {
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to search Purchased Package Plan`,
                variant: "error",
              },
            },
            {
              message: `Unable to get search Purchased Package Plan`,
              variant: "error",
            }
          );
        })
        .finally(() => {
          active && setLoading(false);
        });
    }
  }, [open]);

  return (
    <React.Fragment>
      <LoadableDialog
        open={open}
        onClose={onClose}
        loading={loading}
        title="Latest Package Plan"
      >
        <Table style={{ marginBottom: 5 }}>
          <TableHead></TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{latestPackage && latestPackage.name}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>{latestPackage && latestPackage.type}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Expiration Date</TableCell>
              <TableCell>
                {latestPackage && latestPackage.expirationDate}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Items</TableCell>
              <TableCell>
                <div className={classes.packageTitle}>Main Package Item</div>
                <Table className={classes.table} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.textCenter}>
                        Store
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        Category
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        Total
                      </TableCell>
                      <TableCell className={classes.textCenter}>Used</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {latestPackage &&
                      latestPackage.mainBranch.map((item) => (
                        <TableRow hover key={item.offeringCategoryName}>
                          <TableCell className={classes.textCenter}>
                            {item && item.storeName}
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            {item && item.offeringCategoryName}
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            {latestPackage.originalTicketCount}
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            {item && item.calculatedCount}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                {latestPackage && latestPackage.additionalBranch.length > 0 ? (
                  <div>
                    <div className={classes.packageTitle}>
                      Additional Package Item
                    </div>
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.textCenter}>
                            Store
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Category
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            ExchangeRate
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Used
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {latestPackage &&
                          latestPackage.additionalBranch.map((item) => (
                            <TableRow hover key={item.offeringCategoryName}>
                              <TableCell className={classes.textCenter}>
                                {item && item.storeName}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {item && item.offeringCategoryName}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {item && item.exchangeRate}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {item &&
                                  item.usedPurchasedPackagePlanItem /
                                    (item.exchangeRate ? item.exchangeRate : 1)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <div></div>
                )}

                {latestPackage && latestPackage.totalBranch.length > 0 ? (
                  <div>
                    <div className={classes.packageTitle}>Total</div>
                    <Table className={classes.table} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.textCenter}>
                            Store
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Category
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Total
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            Used
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {latestPackage &&
                          latestPackage.totalBranch.map((item) => (
                            <TableRow hover key={item.offeringCategoryName}>
                              <TableCell className={classes.textCenter}>
                                {item && item.storeName}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {item && item.offeringCategoryName}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {entity &&
                                  entity.latestExpirationPackagePlan
                                    .originalTicketCount}
                              </TableCell>
                              <TableCell className={classes.textCenter}>
                                {item && item.usedTicketCount}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                ) : (
                  <div></div>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </LoadableDialog>
    </React.Fragment>
  );
}

export default LatestPackageDialog;

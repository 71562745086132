import React, { useCallback, useState } from "react";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ReportCard from "./components/ReportCard";
import Typography from "@material-ui/core/Typography";
import MonthlyNmuReportConfigFormDialog from "./components/MonthlyNmuReportConfigFormDialog";
import PackagePlanBalanceReportConfigFormDialog from "./components/PackagePlanBalanceReportConfigFormDialog";
import LastAccessCustomerUserReportConfigFormDialog from "./components/LastAccessCustomerUserReportConfigFormDialog";
import ExpirationDateCustomerUserReportConfigFormDialog from "./components/ExpirationDateCustomerUserReportConfigFormDialog";
import MonthlyPaymentNmuReportConfigFormDialog from "./components/MonthlyPaymentNmuReportConfigFormDialog";
import InstructorClassRecordReportConfigFormDialog from "./components/InstructorClassRecordReportConfigFormDialog";
import PTReportConfigFormDialog from "./components/PTReportConfigFormDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    padding: theme.spacing(6),
  },
  card: {
    width: theme.spacing(40),
  },
  cardContent: {
    height: theme.spacing(24),
  },
}));

export default function ReportList() {
  const classes = useStyles();

  /*
  Monthly Nmu report config dialog
   */

  const [
    monthlyNmuReportConfigFormDialogState,
    setMonthlyNmuReportConfigFormDialogState,
  ] = useState(false);

  const handleMonthlyNmuReportConfigFormDialogOpen = useCallback(() => {
    setMonthlyNmuReportConfigFormDialogState(true);
  }, []);

  const handleMonthlyNmuReportConfigFormDialogClose = useCallback(() => {
    setMonthlyNmuReportConfigFormDialogState(false);
  }, []);

  const monthlyNmuReportConfigFormDialog = (
    <MonthlyNmuReportConfigFormDialog
      open={monthlyNmuReportConfigFormDialogState}
      onOpen={handleMonthlyNmuReportConfigFormDialogOpen}
      onClose={handleMonthlyNmuReportConfigFormDialogClose}
    />
  );

  /*
  Package plan balance report config dialog
   */

  // const [
  //   packagePlanBalanceReportConfigFormDialogState,
  //   setPackagePlanBalanceReportConfigFormDialogState,
  // ] = useState(false);

  // const handlePackagePlanBalanceReportConfigFormDialogOpen = useCallback(() => {
  //   setPackagePlanBalanceReportConfigFormDialogState(true);
  // }, []);

  // const handlePackagePlanBalanceReportConfigFormDialogClose = useCallback(() => {
  //   setPackagePlanBalanceReportConfigFormDialogState(false);
  // }, []);

  // const packagePlanBalanceReportConfigFormDialog = (
  //   <PackagePlanBalanceReportConfigFormDialog
  //     open={packagePlanBalanceReportConfigFormDialogState}
  //     onOpen={handlePackagePlanBalanceReportConfigFormDialogOpen}
  //     onClose={handlePackagePlanBalanceReportConfigFormDialogClose}
  //   />
  // );

  /*
  Last access customer user report config dialog
   */

  // const [
  //   lastAccessCustomerUserReportConfigFormDialogState,
  //   setLastAccessCustomerUserReportConfigFormDialogState,
  // ] = useState(false);

  // const handleLastAccessCustomerUserReportConfigFormDialogOpen = useCallback(() => {
  //   setLastAccessCustomerUserReportConfigFormDialogState(true);
  // }, []);

  // const handleLastAccessCustomerUserReportConfigFormDialogClose = useCallback(() => {
  //   setLastAccessCustomerUserReportConfigFormDialogState(false);
  // }, []);

  // const lastAccessCustomerUserReportConfigFormDialog = (
  //   <LastAccessCustomerUserReportConfigFormDialog
  //     open={lastAccessCustomerUserReportConfigFormDialogState}
  //     onOpen={handleLastAccessCustomerUserReportConfigFormDialogOpen}
  //     onClose={handleLastAccessCustomerUserReportConfigFormDialogClose}
  //   />
  // );

  /*
  Expiration date customer user report config dialog
   */

  // const [
  //   expirationDateCustomerUserReportConfigFormDialogState,
  //   setExpirationDateCustomerUserReportConfigFormDialogState,
  // ] = useState(false);

  // const handleExpirationDateCustomerUserReportConfigFormDialogOpen = useCallback(() => {
  //   setExpirationDateCustomerUserReportConfigFormDialogState(true);
  // }, []);

  // const handleExpirationDateCustomerUserReportConfigFormDialogClose = useCallback(() => {
  //   setExpirationDateCustomerUserReportConfigFormDialogState(false);
  // }, []);

  // const expirationDateCustomerUserReportConfigFormDialog = (
  //   <ExpirationDateCustomerUserReportConfigFormDialog
  //     open={expirationDateCustomerUserReportConfigFormDialogState}
  //     onOpen={handleExpirationDateCustomerUserReportConfigFormDialogOpen}
  //     onClose={handleExpirationDateCustomerUserReportConfigFormDialogClose}
  //   />
  // );

  /*
  Monthly Payment Nmu report config dialog
   */

  const [
    monthlyPaymentNmuReportConfigFormDialogState,
    setMonthlyPaymentNmuReportConfigFormDialogState,
  ] = useState(false);

  const handleMonthlyPaymentNmuReportConfigFormDialogOpen = useCallback(() => {
    setMonthlyPaymentNmuReportConfigFormDialogState(true);
  }, []);

  const handleMonthlyPaymentNmuReportConfigFormDialogClose = useCallback(() => {
    setMonthlyPaymentNmuReportConfigFormDialogState(false);
  }, []);

  const monthlyPaymentNmuReportConfigFormDialog = (
    <MonthlyPaymentNmuReportConfigFormDialog
      open={monthlyPaymentNmuReportConfigFormDialogState}
      onOpen={handleMonthlyPaymentNmuReportConfigFormDialogOpen}
      onClose={handleMonthlyPaymentNmuReportConfigFormDialogClose}
    />
  );

  /*
  Instructor's Class Record report config dialog
   */

  // const [
  //   instructorClassRecordReportConfigFormDialogState,
  //   setInstructorClassRecordReportConfigFormDialogState,
  // ] = useState(false);

  // const handleInstructorClassRecordReportConfigFormDialogOpen = useCallback(() => {
  //   setInstructorClassRecordReportConfigFormDialogState(true);
  // }, []);

  // const handleInstructorClassRecordReportConfigFormDialogClose = useCallback(() => {
  //   setInstructorClassRecordReportConfigFormDialogState(false);
  // }, []);

  // const instructorClassRecordReportConfigFormDialog = (
  //   <InstructorClassRecordReportConfigFormDialog
  //     open={instructorClassRecordReportConfigFormDialogState}
  //     onOpen={handleInstructorClassRecordReportConfigFormDialogOpen}
  //     onClose={handleInstructorClassRecordReportConfigFormDialogClose}
  //   />
  // );

  /*
  PT Record report dialohg
  */
  /*
  Monthly Nmu report config dialog
   */

  const [
    ptReportConfigFormDialogState,
    setPTReportConfigFormDialogState,
  ] = useState(false);

  const handlePTReportConfigFormDialogOpen = useCallback(() => {
    setPTReportConfigFormDialogState(true);
  }, []);

  const handlePTReportConfigFormDialogClose = useCallback(() => {
    setPTReportConfigFormDialogState(false);
  }, []);

  const ptReportConfigFormDialog = (
    <PTReportConfigFormDialog
      open={ptReportConfigFormDialogState}
      onOpen={handlePTReportConfigFormDialogOpen}
      onClose={handlePTReportConfigFormDialogClose}
    />
  );

  return (
    <Frame className={classes.root}>
      <Typography className={classes.title} variant="h1" align="center">
        System Reports
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        {/*<Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Account Balance"
            onStart={handlePackagePlanBalanceReportConfigFormDialogOpen}
          >
            Show account balances for clients. Clients can be filtered by
            specifying a more-than amount.
          </ReportCard>
          {packagePlanBalanceReportConfigFormDialog}
  </Grid>*/}
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Monthly Nmu"
            onStart={handleMonthlyNmuReportConfigFormDialogOpen}
          >
            Show Monthly Nmu records
          </ReportCard>
          {monthlyNmuReportConfigFormDialog}
        </Grid>
        {/*<Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Client Membership"
            onStart={handleLastAccessCustomerUserReportConfigFormDialogOpen}
          >
            Show information for clients. Clients can be filtered by their age,
            sex.
          </ReportCard>
          {lastAccessCustomerUserReportConfigFormDialog}
        </Grid>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Package Expiration"
            onStart={handleExpirationDateCustomerUserReportConfigFormDialogOpen}
          >
            Show clients whose packages are going to expire. Clients can be
            filtered by expiring start date and expiring end date.
          </ReportCard>
          {expirationDateCustomerUserReportConfigFormDialog}
        </Grid>*/}
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Monthly Payment Nmu"
            onStart={handleMonthlyPaymentNmuReportConfigFormDialogOpen}
          >
            Show Monthly Payment Nmu records
          </ReportCard>
          {monthlyPaymentNmuReportConfigFormDialog}
        </Grid>
        <Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="PT Record"
            onStart={handlePTReportConfigFormDialogOpen}
          >
            Show PT Record
          </ReportCard>
          {ptReportConfigFormDialog}
        </Grid>
        {/*<Grid item>
          <ReportCard
            className={classes.card}
            contentClassName={classes.cardContent}
            title="Instructor's Class Record"
            onStart={handleInstructorClassRecordReportConfigFormDialogOpen}
          >
            Show instructor's class record by start date and end date
          </ReportCard>
          {instructorClassRecordReportConfigFormDialog}
        </Grid>
        */}
      </Grid>
    </Frame>
  );
}

import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { Formik, useFormikContext } from "formik";
import oauth2Service from "../../../services/oauth2.service";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { sessionStatusLoadLogin } from "../../../security/SecurityFilter/actions/sessionStatus.action";
import * as Yup from "yup";
import { FormattedMessage } from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import FormikTextField from "../../../components/inputs/FormikTextField";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  field: {
    marginBottom: theme.spacing(2),
  },
}));

const loginFormSchema = Yup.object().shape({
  username: Yup.string()
    .min(3, <FormattedMessage id="login.form.username.error.length.min" />)
    .max(50, <FormattedMessage id="login.form.username.error.length.max" />)
    .required(<FormattedMessage id="login.form.username.error.required" />),
  password: Yup.string()
    .min(6, <FormattedMessage id="login.form.password.error.length.min" />)
    .max(50, <FormattedMessage id="login.form.password.error.length.max" />)
    .required(<FormattedMessage id="login.form.password.error.required" />),
});

function LoginForm(props) {
  const { recaptchaRef } = props;
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    setSubmitting,
    values,
  } = useFormikContext();

  const classes = useStyles();

  const handleRecaptcha = (value) => {
    setSubmitting(false);
  };

  useEffect(() => {
    if (!values.recaptcha) {
      recaptchaRef.current.execute();
      setSubmitting(false);
    }
  }, []);

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <FormikTextField
        className={classes.field}
        id="username"
        name="username"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="login.form.username" />}
        autoComplete="username"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PersonOutlineIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
      <FormikTextField
        className={classes.field}
        id="password"
        name="password"
        type="password"
        variant="outlined"
        fullWidth
        label={<FormattedMessage id="login.form.password" />}
        autoComplete="current-password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockOpenIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        //sitekey="6LeGkJEfAAAAAEod4IHmNTUkNTJ4xRMpumv0GQ3W"
        // Panda
        sitekey="6Lc091sjAAAAAMbeLBA49lw2Hxw36SlmRb9-beBs"
        // Anson Test Key
        //sitekey="6LcMRbYlAAAAAPwzYWC6w-xDjhvc2aJK3vUQUXIB"
        onChange={handleRecaptcha}
        onExpired={() => {
          recaptchaRef.current.reset();
        }}
      />
      <Box position="relative">
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          disabled={isSubmitting || !isValid || !dirty}
        >
          {!isSubmitting ? (
            <FormattedMessage id="login.form.button.login" />
          ) : (
            <FormattedMessage id="login.form.button.loading" />
          )}
        </Button>
      </Box>
    </form>
  );
}

function FormikLoginForm(props) {
  const recaptchaRef = React.createRef();
  return (
    <Formik
      validationSchema={loginFormSchema}
      onSubmit={(values, formikActions) => {
        values.recaptcha = recaptchaRef.current.getValue();
        return oauth2Service
          .requestToken(values.username, values.password, values.recaptcha)
          .then(() => {
            formikActions.setSubmitting(false);
            props.sessionStatusLoadLogin();
          })
          .catch((response) => {
            recaptchaRef.current.reset();
            recaptchaRef.current.execute();
            if (response.status === 400 || response.status === 401) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.failed" />
              );
            } else if (response.status === 500) {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.server" />
              );
            } else {
              formikActions.setFieldError(
                "password",
                <FormattedMessage id="login.form.error.auth.unknown" />
              );
            }

            //formikActions.setSubmitting(false);
          });
      }}
      initialValues={{ username: "", password: "", recaptcha: "" }}
    >
      <LoginForm recaptchaRef={recaptchaRef} />
    </Formik>
  );
}

export default connect(null, { sessionStatusLoadLogin })(FormikLoginForm);

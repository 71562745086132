import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { PACKAGE_PLAN_TYPE_VALUE_NAME_MAPPING } from "../../../constants/app.constant";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import LabelledStatusBullet from "../../../components/LabelledStatusBullet";

import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import { TableHead } from "@material-ui/core";

const DisplayDialog = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/packagePlans/${entity.id}`)
      .then((response) => {
        active && setDetailedEntity(response.data);
      })
      .catch((error) => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch package plan "${entity.name}"`,
                variant: "error",
              },
            },
            {
              message: `Unable to fetch package plan "${entity.name}"`,
              variant: "error",
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Package Plan Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{detailedEntity.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Categories</TableCell>
            <TableCell>
              {detailedEntity.offeringCategories
                ? detailedEntity.offeringCategories
                    .map((item) => {
                      return item.name;
                    })
                    .join(", ")
                : "--"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Price</TableCell>
            <TableCell>${detailedEntity.price}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>
              {entity.status ? (
                <LabelledStatusBullet color="success" label="Active" />
              ) : (
                <LabelledStatusBullet color="default" label="Inactive" />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Apply for all categories</TableCell>
            <TableCell>
              {entity.allOfferingCategoryApplied ? (
                <LabelledStatusBullet color="success" label="Yes" />
              ) : (
                <LabelledStatusBullet color="default" label="No" />
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;

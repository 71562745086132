import { Formik, useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import { PACKAGE_PLAN_TYPE_OPTIONS, CLIENT_MANAGEMENT_PACKAGE_PLAN_STATUS_OPTIONS } from "../../../constants/app.constant";
import React from "react";
import FilterFormButtonGroup from "../../../components/filters/FilterFormButtonGroup";

const FilterForm = (props) => {
  const { loading } = props;

  const { handleSubmit, handleReset, values } = useFormikContext();

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid container spacing={2}>
        <Grid item md={3} xs={6}>
          <FormikTextField
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        {/* <Grid item md={3} xs={6}>
          <FormikAutocomplete
            name="type"
            margin="dense"
            autoHighlight
            options={PACKAGE_PLAN_TYPE_OPTIONS}
            getOptionLabel={option => option && (option.label || "")}
            TextFieldProps={{
              label: "Type",
              variant: "outlined",
              fullWidth: true
            }}
          />
        </Grid> */}
        <Grid item md={3} xs={6}>
          <FormikAutocomplete
            name="status"
            margin="dense"
            autoHighlight
            options={CLIENT_MANAGEMENT_PACKAGE_PLAN_STATUS_OPTIONS}
            getOptionLabel={option => option && (option.label || "")}
            TextFieldProps={{
              label: "Status",
              variant: "outlined",
              fullWidth: true
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FilterFormButtonGroup loading={loading} align="left" />
        </Grid>
      </Grid>
    </form>
  );
};

export default function(props) {
  const { loading, onSubmit, onReset } = props;

  return (
    <Formik
      initialValues={{
        name: "",
        // type: null,
        packagePlanName: "",
      }}
      onSubmit={onSubmit}
      onReset={onReset}
    >
      <FilterForm loading={loading} />
    </Formik>
  );
}

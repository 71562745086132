import React, { useState, useEffect } from "react";
import Frame from "../../components/Frame";
import { makeStyles } from "@material-ui/core";
import PageHeader from "../../components/PageHeader";
import {
  useApiNotification,
  useNotification,
} from "../../hooks/notification.hook";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import PerfectScrollbar from "react-perfect-scrollbar";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  mainContent: {
    display: "flex",
    width: "100%",
    alignContent: "center",
    marginBottom: "5px",
  },
  mailingList: {
    flex: 1,
    marginRight: "20px",
  },
  mailingContent: {
    flex: 4,
  },
  card: {
    flex: 1,
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    whiteSpace: "nowrap",
  },
  columnAction: {
    minWidth: 160,
  },
}));

function EmailClient(props) {
  const { filterFormInitialValues, onFilter } = props;
  const classes = useStyles();

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const [loading, setLoading] = useState(false);
  const [mailingList, setMailingList] = useState([]);
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");

  // settings for filtering the recipients
  const [activePackage, setActivePackage] = useState(false);
  const [inactivePackage, setInactivePackage] = useState(false);
  const [branch, setBranch] = useState(null);

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  //   const [branches, setBranches] = React.useState([]);

  /*
   Listing table pagination
   */

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const [showTableWhileLoading, setShowTableWhileLoading] = useState(false);

  const handlePageNumberChange = (event, page) => {
    setPageNumber(page);
    setShowTableWhileLoading(true);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPageNumber(0);
    setShowTableWhileLoading(true);
  };

  const handleRefreshListingTable = (event) => {
    setShowTableWhileLoading(true);
    setFilters({ ...filters });
  };

  /*
  Filter
   */

  const [filters, setFilters] = React.useState(
    filterFormInitialValues
      ? onFilter
        ? onFilter(filterFormInitialValues)
        : filterFormInitialValues
      : {}
  );
  const handleFilterSubmit = (values) => {
    setShowTableWhileLoading(false);
    setPageNumber(0);

    setFilters(onFilter ? onFilter(values) : values);
  };

  const handleToggleRecipient = (email) => {
    if (mailingList.includes(email)) {
      const newMailingList = mailingList.filter((mail) => mail !== email);
      setMailingList(newMailingList);
    } else {
      setMailingList([...mailingList, email]);
    }
  };

  const handleAddAll = () => {
    if (mailingList.length === clients.length) setMailingList([]);
    else setMailingList(filteredClients.map((client) => client.email));
  };

  const sendEmails = async () => {
    try {
      if (mailingList.length === 0) throw "No recipient";
      await axios
        .post("/api/sendEmail", {
          mailingList: mailingList,
          template: content,
          subject: subject,
        })
        .then((res) => {
          setContent("");
          setSubject("");
          notify(`Sent`, "success");
        });
    } catch (error) {
      notifyApiError(
        error.response.status,
        {},
        {
          message: `Unable to send email`,
          variant: "error",
        }
      );
    }
  };

  const fetctEmailClientAndSetPage = (page) => {
    setLoading(true);
    setPageNumber(page);

    const params = {
      page: page,
      size: pageSize,
      activePackage: activePackage,
      inactivePackage: inactivePackage,
    };

    let retrieveData = async () => {
      let returnClients = await axios.get(`/api/sendEmail/client`, {
        params: params,
      });
      setClients(returnClients.data.content);
      setFilteredClients(returnClients.data.content);
      setTotalCount(returnClients.data["totalElements"]);
      //   let returnBranches = await axios.get("/api/storeBranches");
      //   setBranches(returnBranches.data.content);
    };
    retrieveData().then(() => setLoading(false));
  };

  useEffect(() => {
    fetctEmailClientAndSetPage(pageNumber);
  }, [filters, pageNumber]);

  React.useEffect(() => {
    fetctEmailClientAndSetPage(0);
  }, [activePackage, inactivePackage, branch, pageSize]);

  return (
    <Frame className={classes.root}>
      <PageHeader section="Adminstration" title="Email Client" />
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={activePackage}
              onChange={() => setActivePackage(!activePackage)}
              name="activePackage"
              color="primary"
            />
          }
          label="Have active package(s)"
          disabled={inactivePackage}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={inactivePackage}
              onChange={() => setInactivePackage(!inactivePackage)}
              name="inactivePackage"
              color="primary"
            />
          }
          label="Have inactive package(s)"
          disabled={activePackage}
        />
        {/* <FormControl style={{ marginBottom: 5, minWidth: 120 }}>
        <InputLabel>Branch</InputLabel>
        <Select value={branch} onChange={event => setBranch(event.target.value)}>
          {branches.map(b => (
            <MenuItem value={b.id}>{b.name}</MenuItem>
          ))}
        </Select>
      </FormControl> */}
      </div>
      <br />

      <div className={classes.mainContent}>
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              <div className={classes.inner}>
                <Table className={classes.mailingList} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={mailingList.length === clients.length}
                          onChange={handleAddAll}
                        />
                      </TableCell>
                      <TableCell padding="default">Name</TableCell>
                      <TableCell padding="default">Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredClients.map((client) => {
                      return (
                        <React.Fragment>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={mailingList.includes(client.email)}
                                onChange={() =>
                                  handleToggleRecipient(client.email)
                                }
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                            >
                              {client.firstName} {client.lastName}
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"
                              padding="default"
                            >
                              {client.email}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </div>
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={totalCount}
              onPageChange={handlePageNumberChange}
              onRowsPerPageChange={handlePageSizeChange}
              page={pageNumber}
              rowsPerPage={pageSize}
              rowsPerPageOptions={[5, 10, 25, 50]}
            />
          </CardActions>
          {loading && (
            <div className={classes.progress}>
              <CircularProgress />
            </div>
          )}
        </Card>
      </div>
      <hr />
      <div className={classes.mailingContent}>
        <TextField
          id="standard-required"
          label="Subject"
          value={subject}
          onChange={(event) => setSubject(event.target.value)}
          style={{ width: "100%" }}
        />
        <hr />
        <div style={{ display: "flex" }}>
          <TextField
            id="outlined-multiline-static"
            label="Content"
            multiline
            rows={4}
            value={content}
            variant="outlined"
            onChange={(event) => setContent(event.target.value)}
            style={{
              width: "100%",
              height: "100%",
              flex: 1,
              marginRight: "10px",
            }}
          />
          <p style={{ flex: 1 }}>
            Preview: <hr />
            <div
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            ></div>
            <hr />
            <span style={{ marginRight: 5, fontWeight: "bold" }}>
              Total Recipients: {mailingList.length}
            </span>
            <div style={{ float: "right" }}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => sendEmails()}
              >
                Send
              </Button>
            </div>
          </p>
        </div>
      </div>
    </Frame>
  );
}

export default EmailClient;

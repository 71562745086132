import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useFormikContext } from "formik";
import AutoCompleteOffTextField from "./AutoCompleteOffTextField";

export default function(props) {
  const { name, onChange, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting,
  } = useFormikContext();

  return (
    <KeyboardDatePicker
      autoOk
      inputVariant="outlined"
      format="DD/MM/YYYY"
      placeholder="DD/MM/YYYY"
      margin="dense"
      fullWidth
      InputAdornmentProps={{ position: "start" }}
      KeyboardButtonProps={{ size: "small" }}
      TextFieldComponent={AutoCompleteOffTextField}
      disabled={isSubmitting}
      value={values[name]}
      onChange={(date) => {
        setFieldValue(name, date);
        onChange && onChange(date, date);
      }}
      {...rest}
      name={name}
      onClose={() => {
        setFieldTouched(name, true, false);
      }}
      onBlur={handleBlur}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      onError={(error) => {
        if (error && errors[name] !== error) {
          setFieldError(name, error);
        }
      }}
    />
  );
}

import React, { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import axios from "axios";
import Frame from "./Frame";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  table: {
    marginTop: theme.spacing(3)
  },
  emptyFilterResult: {
    flex: "1 1 auto"
  },
  errorFilterResult: {
    flex: "1 1 auto"
  },
  progress: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const CRMEntityManagement = props => {
  const {
    pageHeader: PageHeader,
  } = props;

  const classes = useStyles();

 


  return (
    <Frame className={classes.root}>
      <PageHeader/>

    </Frame>
  );
};

export default CRMEntityManagement;

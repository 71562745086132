import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { useFormikContext } from "formik";
import FormikOfferingCategorySelect from "components/inputs/FormikOfferingCategorySelect";
import { coreCreateParams } from "components/CoreCreateParams";
import FormikMultiReferenceField from "components/inputs/FormikMultiReferenceField";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    ...theme.typography.h5,
  },
}));

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();
  const classes = useStyles();
  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        <Grid item md={6} xs={12}>
          <FormikTextField
            required
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikTextField
            required
            fullWidth
            type="number"
            label="Price / Class"
            name="price"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <FormikMultiReferenceField
            //required
            fullWidth
            name="offeringCategories"
            label="Category"
            margin="dense"
            variant="outlined"
            apiUrl={"/api/offeringCategories/variants/references"}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikSwitch
            name="status"
            label="Toggle Package Plan Status"
            description="Indicate if this package plan is still used"
            FormLabelProps={{
              className: classes.sectionLabel,
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikSwitch
            name="allOfferingCategoryApplied"
            label="Apply for all categories"
            description="Indicate if this package plan apply for all categories"
            FormLabelProps={{
              className: classes.sectionLabel,
            }}
          />
        </Grid>
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  price: Yup.string().required("Required"),
});

export const initialValues = {
  name: "",
  price: "",
  status: true,
  allOfferingCategoryApplied: false,
  offeringCategories: [],
};

export const createParams = (values) => {
  const editableField = [
    "name",
    "price",
    "status",
    "allOfferingCategoryApplied",
    "offeringCategoriesId",
  ];

  const selectedCategories = [];
  values.offeringCategories &&
    values.offeringCategories.map((item) => selectedCategories.push(item.id));
  values.offeringCategoriesId = selectedCategories;
  delete values.offeringCategories;

  const params = coreCreateParams(editableField, values);

  return params;
};

export const LOGIN_PATH = "/login";
export const FORGET_PASSWORD_PATH = "/forgetpassword";
export const NEW_PASSWORD_PATH = "/newpassword";

export const BRANCH_MANAGEMENT_PATH = "/branches";
export const STAFF_USER_MANAGEMENT_PATH = "/staffs";
export const CUSTOMER_USER_MANAGEMENT_PATH = "/customerUsers";
export const CUSTOMER_USER_PACKAGE_PLAN_MANAGEMENT_PATH =
  "/customerUsers/:id/packagePlans";
export const CUSTOMER_USER_PACKAGE_PLAN_PAYMENT_RECORD_MANAGEMENT_PATH =
  "/customerUsers/:id/packagePlans/:packageId/paymentRecords";
export const CUSTOMER_USER_PURCHASED_PACKAGE_PLAN_LISTING_PATH =
  "/customerUsers/:id/purchasedPackagePlanUsages";
export const MEMBERSHIP_TIER_MANAGEMENT_PATH = "/membershipTiers";
export const OFFERING_CATEGORY_MANAGEMENT_PATH = "/offeringCategories";
export const OFFERING_MANAGEMENT_PATH = "/offerings";
export const OFFERING_SCHEDULE_MANAGEMENT_PATH =
  "/offerings/:id/offeringSchedules";
export const OFFERING_SCHEDULE_SESSION_MANAGEMENT_PATH =
  "/offeringSchedules/:id/offeringScheduleSessions";
export const OFFERING_SCHEDULE_SESSION_TRANSCRIPT_MANAGEMENT_PATH =
  "/offeringScheduleSessions/:id/transcripts";
export const PACKAGE_PLAN_MANAGEMENT_PATH = "/packagePlans";
export const REPORT_LIST_PATH = "/reports";
export const NOTIFICATION_MANAGEMENT_PATH = "/notifications";
export const MAIL_TEMPLATE_MANAGEMENT_PATH = "/mailTemplates";
export const CRM_DATA_IMPORT_MANAGEMENT_PATH = "/CRMDataImport";

export const OFFERING_SCHEDULE_SESSION_LIST_PATH = "/offeringScheduleSessions";
export const SYSTEM_CONFIGURATION_PATH = "/systemConfiguration";
export const CLOSED_BUSINESS_DAY_MANAGEMENT_PATH = "/closedBusinessDays";
export const FRONTEND__CONFIGURATION_PATH = "/frontendConfiguration";

export const TRAINING_HISTORY_LIST = "/trainingHistoryList/:id";

export const PROMOTION_MANAGEMENT_PATH = "/promotions";

export const EMAIL_CLIENT_PATH = "/mail-clients";

import React, { useCallback } from "react";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import AutocompletePlus from "./AutocompletePlus";

const LoadableSelect = (props) => {
  const {
    request,
    required,
    name,
    label,
    placeholder,
    onBlur,
    onLoadError,
    fullWidth,
    variant,
    margin,
    disabled,
    error,
    helperText,
    ...rest
  } = props;

  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  React.useEffect(() => {
    let active = true;

    if (!open) {
      return;
    }

    setLoading(true);

    request()
      .then((response) => {
        active && setOptions(response.data || []);
      })
      .catch((error) => {
        if (active) {
          onLoadError(error);
          setOpen(false);
        }
      })
      .finally(() => active && setLoading(false));

    return () => {
      active = false;
    };
  }, [open, onLoadError, request]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const dense = margin === "dense";

  return (
    <AutocompletePlus
      {...rest}
      margin={margin}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      loading={loading}
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          label={label}
          name={name}
          onBlur={onBlur}
          fullWidth={fullWidth}
          variant={variant}
          margin={margin}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={dense ? 20 : 24} />
                ) : (
                  params.InputProps.endAdornment
                )}
              </React.Fragment>
            ),
          }}
          disabled={disabled}
          error={error}
          helperText={helperText}
        />
      )}
      disabled={disabled}
    />
  );
};

export default LoadableSelect;

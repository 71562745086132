import React, { useState } from "react";
import { Button, makeStyles, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  paper: {
    // width: 700,
    // maxWidth: "100%"
  },
  title: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  actions: {
    padding: theme.spacing(2, 3),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
  },
}));

export default function(props) {
  const {
    paperClassName,
    title,
    open,
    loading,
    onClose,
    onSubmit,
    onReset,
    submitButtonDisabled,
    resetButtonDisabled,
    children,
    schedule,
    onSchedule,
    ...rest
  } = props;

  const classes = useStyles();

  const [schedules, setSchedules] = React.useState({
    startDate: null,
    endDate: null,
  });

  const [sessionTime, setSessionTime] = React.useState({
    startTime: null,
    endTime: null,
  });

  const [fieldError, setFieldError] = React.useState({
    startDate: "Please fill in both start date and end date",
    endDate: "Please fill in both start date and end date",
    startTime: null,
    endTime: null,
  });

  return (
    <Dialog
      disableRestoreFocus
      scroll="body"
      maxWidth={"md"}
      {...rest}
      open={open}
      onClose={onClose}
      PaperProps={{
        className: clsx(classes.paper, paperClassName),
      }}
    >
      <DialogTitle className={classes.title} disableTypography>
        <Typography variant="h3">Edit Class Schedule</Typography>
        {onClose && (
          <IconButton className={classes.closeButton} onClick={onClose}>
            <CloseOutlinedIcon />
          </IconButton>
        )}
      </DialogTitle>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>Class Name</TableCell>
              <TableCell>{schedule.className}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Instructor</TableCell>
              <TableCell>{schedule.instructor}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder={moment(
                      schedule.startDate,
                      "YYYY-MM-DD"
                    ).format("DD/MM/YYYY")}
                    margin="dense"
                    label="Start Day"
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{ size: "small" }}
                    name="startDate"
                    value={schedules.startDate}
                    error={Boolean(fieldError.startDate !== null)}
                    helperText={<span>{fieldError.startDate}</span>}
                    onChange={(date) => {
                      setSchedules({
                        ...schedules,
                        startDate: date ? date : null,
                      });
                      // if((moment(date, 'YYYY-MM-DD').isBefore(moment(schedule.startDate, 'YYYY-MM-DD'))))
                      //   setFieldError({...fieldError, startDate: "Cannot be earlier than original start date"});
                      // else if((moment(date, 'YYYY-MM-DD').isSameOrAfter(moment(schedule.endDate, 'YYYY-MM-DD'))))
                      //   setFieldError({...fieldError, startDate: "Cannot be later or equal to original end date"});
                      // else
                      //   setFieldError({...fieldError, startDate: null});
                      setFieldError({
                        ...fieldError,
                        startDate:
                          "Please fill in both start date and end date",
                        endDate: "Please fill in both start date and end date",
                      });
                      if (
                        moment(date, "YYYY-MM-DD").isSameOrAfter(
                          moment(schedules.endDate, "YYYY-MM-DD")
                        )
                      ) {
                        setFieldError({
                          ...fieldError,
                          startDate:
                            "Incorrect date format, end date is earlier than start date",
                          endDate:
                            "Incorrect date format, end date is earlier than start date",
                        });
                      } else if (date != null && schedules.endDate != null) {
                        setFieldError({
                          ...fieldError,
                          startDate: null,
                          endDate: null,
                        });
                      }
                    }}
                    {...rest}
                  />
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    placeholder={moment(schedule.endDate, "YYYY-MM-DD").format(
                      "DD/MM/YYYY"
                    )}
                    margin="dense"
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{ size: "small" }}
                    label="End Date"
                    name="endDate"
                    value={schedules.endDate}
                    helperText={<span>{fieldError.endDate}</span>}
                    onChange={(date) => {
                      setSchedules({
                        ...schedules,
                        endDate: date ? date : null,
                      });
                      // if((moment(date, 'YYYY-MM-DD').isSameOrBefore(moment(schedule.startDate, 'YYYY-MM-DD'))))
                      //   setFieldError({...fieldError, endDate: "Cannot be earlier or equal to original start date"});
                      // else if((moment(date, 'YYYY-MM-DD').isAfter(moment(schedule.endDate, 'YYYY-MM-DD'))))
                      //   setFieldError({...fieldError, endDate: "Cannot be later than original end date"});
                      // else
                      //   setFieldError({...fieldError, endDate: null});
                      setFieldError({
                        ...fieldError,
                        startDate:
                          "Please fill in both start date and end date",
                        endDate: "Please fill in both start date and end date",
                      });
                      if (
                        moment(date, "YYYY-MM-DD").isSameOrBefore(
                          moment(schedules.startDate, "YYYY-MM-DD")
                        )
                      ) {
                        setFieldError({
                          ...fieldError,
                          startDate:
                            "Incorrect date format, end date is earlier than start date",
                          endDate:
                            "Incorrect date format, end date is earlier than start date",
                        });
                      } else if (date != null && schedules.startDate != null) {
                        setFieldError({
                          ...fieldError,
                          startDate: null,
                          endDate: null,
                        });
                      }
                    }}
                    {...rest}
                    error={Boolean(fieldError.endDate !== null)}
                  />
                </Grid>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Grid item xs={12}>
                  <KeyboardTimePicker
                    autoOk
                    ampm={false}
                    inputVariant="outlined"
                    format="HH:mm"
                    placeholder={schedule.startTime}
                    margin="dense"
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{ size: "small" }}
                    keyboardIcon={<AccessTimeOutlinedIcon />}
                    label="Start Time"
                    {...rest}
                    error={Boolean(fieldError.startTime !== null)}
                    helperText={<span>{fieldError.startTime}</span>}
                    name="startTime"
                    value={sessionTime.startTime}
                    onChange={(time) => {
                      setSessionTime({
                        ...sessionTime,
                        startTime: time || null,
                      });

                      if (time !== null && sessionTime.endTime !== null) {
                        if (time.isSameOrAfter(sessionTime.endTime))
                          setFieldError({
                            ...fieldError,
                            endTime: "Cannot be earlier or equal to start time",
                          });
                        else setFieldError({ ...fieldError, endTime: null });
                      }
                    }}
                  />
                </Grid>
              </TableCell>
              <TableCell>
                <Grid item xs={12}>
                  <KeyboardTimePicker
                    autoOk
                    ampm={false}
                    inputVariant="outlined"
                    format="HH:mm"
                    placeholder={schedule.endTime}
                    margin="dense"
                    fullWidth
                    InputAdornmentProps={{ position: "start" }}
                    KeyboardButtonProps={{ size: "small" }}
                    keyboardIcon={<AccessTimeOutlinedIcon />}
                    label="End Time"
                    {...rest}
                    error={Boolean(fieldError.endTime !== null)}
                    helperText={<span>{fieldError.endTime}</span>}
                    name="endTime"
                    value={sessionTime.endTime}
                    onChange={(time) => {
                      setSessionTime({
                        ...sessionTime,
                        endTime: time || null,
                      });
                      if (time !== null && sessionTime.startTime !== null) {
                        if (time.isSameOrBefore(sessionTime.startTime))
                          setFieldError({
                            ...fieldError,
                            endTime: "Cannot be earlier or equal to start time",
                          });
                        else setFieldError({ ...fieldError, endTime: null });
                      }
                    }}
                  />
                </Grid>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </MuiPickersUtilsProvider>

      <DialogActions className={classes.actions}>
        <Button
          color="default"
          onClick={() => {
            setSchedules({
              ...schedules,
              startDate: null,
              endDate: null,
            });
            setSessionTime({
              ...sessionTime,
              startTime: null,
              endTime: null,
            });
          }}
          disabled={
            schedules.startDate === null && schedules.endDate === null
            // && sessionTime.startTime === null && sessionTime.endTime === null
          }
        >
          Reset
        </Button>
        <Button
          variant="outlined"
          type="submit"
          color="secondary"
          disabled={
            fieldError.startDate !== null ||
            fieldError.endDate !== null ||
            fieldError.startTime !== null ||
            fieldError.endTime !== null ||
            schedules.startDate === null ||
            schedules.endDate === null ||
            // sessionTime.startTime === null || sessionTime.endTime === null ||
            moment(schedules.startDate, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              schedules.startDate ||
            moment(schedules.endDate, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              schedules.endDate
            // || !moment(sessionTime.startTime, "HH:mm", true).isValid() ||
            // !moment(sessionTime.endTime, "HH:mm", true).isValid()
          }
          onClick={() => {
            onSubmit(schedule);
            var startDate = schedule.startDate;
            var endDate = schedule.endDate;
            if (schedules.startDate !== null)
              startDate = schedules.startDate.format("YYYY-MM-DD");
            if (schedules.endDate !== null)
              endDate = schedules.endDate.format("YYYY-MM-DD");
            var startTime = schedule.startTime;
            var endTime = schedule.endTime;
            if (sessionTime.startTime !== null)
              startTime = sessionTime.startTime;
            if (sessionTime.endTime !== null) endTime = sessionTime.endTime;
            onSchedule(startDate, endDate, startTime, endTime);
            setSchedules({ ...schedules, startDate: null, endDate: null });
            setSessionTime({ ...sessionTime, startTime: null, endTime: null });
          }}
        >
          Save
        </Button>
      </DialogActions>
      {loading && (
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}

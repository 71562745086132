import React from "react";
import clsx from "clsx";
import moment from "moment";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Grid,
  Hidden,
  Typography,
  Tooltip,
  ButtonGroup,
  IconButton,
  Button,
} from "@material-ui/core";
import ViewComfyOutlinedIcon from "@material-ui/icons/ViewComfyOutlined";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import ViewDayOutlinedIcon from "@material-ui/icons/ViewDayOutlined";

const useStyles = makeStyles(() => ({
  root: {},
}));

const Toolbar = (props) => {
  const {
    date,
    view,
    onDatePrev,
    onDateNext,
    onEventAdd,
    onViewChange,
    onDateToday,
    className,
    ...rest
  } = props;

  const classes = useStyles();

  const viewOptions = [
    {
      label: "Day",
      value: "timeGridDay",
      icon: ViewDayOutlinedIcon,
    },
    {
      label: "Week",
      value: "timeGridWeek",
      icon: ViewWeekOutlinedIcon,
    },
    {
      label: "Month",
      value: "dayGridMonth",
      icon: ViewComfyOutlinedIcon,
    },
  ];

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <ButtonGroup>
            <Button onClick={onDatePrev}>Prev</Button>
            <Button onClick={onDateToday}>Today</Button>
            <Button onClick={onDateNext}>Next</Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Typography variant="h3">
            {moment(date).format("dddd, DD MMMM YYYY")}
          </Typography>
        </Grid>
        <Grid item>
          {viewOptions.map((viewOption) => {
            const Icon = viewOption.icon;

            return (
              <Tooltip key={viewOption.value} title={viewOption.label}>
                <IconButton
                  color={viewOption.value === view ? "secondary" : "default"}
                  onClick={() => onViewChange(viewOption.value)}
                >
                  <Icon />
                </IconButton>
              </Tooltip>
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.any.isRequired,
  onDateNext: PropTypes.func,
  onDatePrev: PropTypes.func,
  onDateToday: PropTypes.func,
  onEventAdd: PropTypes.func,
  onViewChange: PropTypes.func,
  view: PropTypes.string.isRequired,
};

export default Toolbar;

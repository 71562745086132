import React from "react";
import { Redirect, Route, Switch } from "react-router";
import {
  CUSTOMER_USER_MANAGEMENT_PATH,
  MEMBERSHIP_TIER_MANAGEMENT_PATH,
  NOTIFICATION_MANAGEMENT_PATH,
  OFFERING_CATEGORY_MANAGEMENT_PATH,
  OFFERING_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_SESSION_MANAGEMENT_PATH,
  PACKAGE_PLAN_MANAGEMENT_PATH,
  REPORT_LIST_PATH,
  STAFF_USER_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_SESSION_LIST_PATH,
  SYSTEM_CONFIGURATION_PATH,
  CUSTOMER_USER_PACKAGE_PLAN_MANAGEMENT_PATH,
  CUSTOMER_USER_PACKAGE_PLAN_PAYMENT_RECORD_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_SESSION_TRANSCRIPT_MANAGEMENT_PATH,
  CLOSED_BUSINESS_DAY_MANAGEMENT_PATH,
  MAIL_TEMPLATE_MANAGEMENT_PATH,
  CUSTOMER_USER_PURCHASED_PACKAGE_PLAN_LISTING_PATH,
  TRAINING_HISTORY_LIST,
  BRANCH_MANAGEMENT_PATH,
  PROMOTION_MANAGEMENT_PATH,
  EMAIL_CLIENT_PATH,
  CRM_DATA_IMPORT_MANAGEMENT_PATH,
} from "../constants/route.constant";
import Error404Page from "../pages/Error404Page";
import { AccessControlRoute } from "./AccessControlRoute";
import {
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MEMBERSHIP_TIER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_YES,
  ACCESS_CONTROL_ENTRY_STORE_BRANCH,
  ACCESS_CONTROL_ENTRY_PROMOTION,
  ACCESS_CONTROL_ENTRY_CRM_DATA_IMPORT,
} from "../constants/app.constant";
import CustomerUserManagement from "../pages/CustomerUserManagement";
import MembershipTierManagement from "../pages/MembershipTierManagement";
import OfferingManagement from "../pages/OfferingManagement";
import OfferingScheduleManagement from "../pages/OfferingScheduleManagement";
import PackagePlanManagement from "../pages/PackagePlanManagement";
import NotificationManagement from "../pages/NotificationManagement";
import ReportList from "../pages/ReportList";
import OfferingScheduleSessionManagement from "../pages/OfferingScheduleSessionManagement";
import UpcomingOfferingScheduleSessionList from "../pages/UpcomingOfferingScheduleSessionList/UpcomingOfferingScheduleSessionList";
import OfferingScheduleSessionList from "../pages/OfferingScheduleSessionList/OfferingScheduleSessionList";
import SystemConfiguration from "../pages/SystemConfiguration/SystemConfiguration";
import CustomerUserPackagePlanManagement from "../pages/CustomerUserPackagePlanManagement/CustomerUserPackagePlanManagement";
import OfferingScheduleSessionTranscriptManagement from "../pages/OfferingScheduleSessionTranscriptManagement/OfferingScheduleSessionTranscriptManagement";
import ClosedBusinessDayManagement from "../pages/ClosedBusinessDayManagement";
import MailTemplateManagement from "../pages/MailTemplateManagement";
import CustomerUserPurchasedPackagePlanUsageListing from "../pages/CustomerUserPurchasedPackagePlanUsageListing/CustomerUserPurchasedPackagePlanUsageListing";
import TranscriptHistoryList from "../pages/TranscriptHistoryList";
import BranchManagement from "../pages/BranchManagement";
import PromotionManagement from "../pages/PromotionManagement";
import StaffUserManagement from "../pages/StaffUserManagement";
import OfferingCategoryManagement from "../pages/OfferingCategoryManagement";
import EmailClient from "../pages/EmailClient/EmailClient";
import CRMDataImport from "../pages/CRMDataImport";
import ClassSchedulesPage from "pages/ClassSchedule";
import CustomerUserPackagePlanPaymentManagement from "pages/CustomerUserPackagePlanPaymentManagement/CustomerUserPackagePlanPaymentManagement";

export default function AppRoute() {
  return (
    <Switch>
      {/* <Route path={OFFERING_SCHEDULE_SESSION_LIST_PATH} component={OfferingScheduleSessionList} exact /> */}
      <Route
        path={OFFERING_SCHEDULE_SESSION_LIST_PATH}
        component={ClassSchedulesPage}
        exact
      />
      <Route
        path={CUSTOMER_USER_PURCHASED_PACKAGE_PLAN_LISTING_PATH}
        component={CustomerUserPurchasedPackagePlanUsageListing}
        exact
      />
      <Route
        path={CUSTOMER_USER_PACKAGE_PLAN_PAYMENT_RECORD_MANAGEMENT_PATH}
        component={CustomerUserPackagePlanPaymentManagement}
        exact
      />

      {/* <Route path={EMAIL_CLIENT_PATH} component={EmailClient} exact /> */}
      {/* <AccessControlRoute
        path={SYSTEM_CONFIGURATION_PATH}
        component={SystemConfiguration}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ]}
      /> */}
      {/* <AccessControlRoute
        path={CLOSED_BUSINESS_DAY_MANAGEMENT_PATH}
        component={ClosedBusinessDayManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ]}
      /> */}
      <AccessControlRoute
        path={STAFF_USER_MANAGEMENT_PATH}
        component={StaffUserManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={OFFERING_CATEGORY_MANAGEMENT_PATH}
        component={OfferingCategoryManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={CUSTOMER_USER_MANAGEMENT_PATH}
        component={CustomerUserManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={CUSTOMER_USER_PACKAGE_PLAN_MANAGEMENT_PATH}
        component={CustomerUserPackagePlanManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      {/* <AccessControlRoute
        path={MEMBERSHIP_TIER_MANAGEMENT_PATH}
        component={MembershipTierManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_MEMBERSHIP_TIER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      /> */}
      <AccessControlRoute
        path={OFFERING_MANAGEMENT_PATH}
        component={OfferingManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={OFFERING_SCHEDULE_MANAGEMENT_PATH}
        component={OfferingScheduleManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={OFFERING_SCHEDULE_SESSION_MANAGEMENT_PATH}
        component={OfferingScheduleSessionManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={OFFERING_SCHEDULE_SESSION_TRANSCRIPT_MANAGEMENT_PATH}
        component={OfferingScheduleSessionTranscriptManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING_SCHEDULE,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={PACKAGE_PLAN_MANAGEMENT_PATH}
        component={PackagePlanManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      {/* <AccessControlRoute
        path={NOTIFICATION_MANAGEMENT_PATH}
        component={NotificationManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      /> */}
      {/* <AccessControlRoute
        path={MAIL_TEMPLATE_MANAGEMENT_PATH}
        component={MailTemplateManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      /> */}
      <AccessControlRoute
        path={REPORT_LIST_PATH}
        component={ReportList}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_REPORT,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ]}
      />
      <AccessControlRoute
        path={TRAINING_HISTORY_LIST}
        component={TranscriptHistoryList}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      <AccessControlRoute
        path={BRANCH_MANAGEMENT_PATH}
        component={BranchManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      />
      {/* <AccessControlRoute
        path={PROMOTION_MANAGEMENT_PATH}
        component={PromotionManagement}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_PROMOTION,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      /> */}
      {/* <AccessControlRoute
        path={CRM_DATA_IMPORT_MANAGEMENT_PATH}
        component={CRMDataImport}
        exact
        accessControls={[
          {
            entry: ACCESS_CONTROL_ENTRY_CRM_DATA_IMPORT,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ]}
      /> */}
      <Redirect from="/" to={OFFERING_SCHEDULE_SESSION_LIST_PATH} exact />
      <Route component={Error404Page} />
    </Switch>
  );
}

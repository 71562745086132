import Grid from "@material-ui/core/Grid";
import React, { useCallback, useContext, useEffect, useState } from "react";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import AutoCompleteOffTextField from "components/inputs/AutoCompleteOffTextField";
import LoadableSelect from "components/inputs/LoadableSelect";

const FilterForm = (props) => {
  const { loading, onValueChange, onCurrentDayChange, values } = props;

  const [userLoading, setUserLoading] = useState(true);

  const [userId, setUserId] = useState("");

  const offeringCategoryRequest = () => {
    return axios.get("/api/offeringCategories/variants/references");
  };

  const storeBranchRequest = () => {
    return (
      !userLoading && axios.get("/api/staffUsers/" + userId + "/storeBranches")
    );
  };

  useEffect(() => {
    axios.get("/api/auth/profile").then((response) => {
      setUserId(response.data.id);
    });
    storeBranchRequest();
    setUserLoading(false);
  }, [userId]);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <KeyboardDatePicker
            autoOk
            fullWidth
            label="Current Day"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            placeholder="DD/MM/YYYY"
            margin="dense"
            InputAdornmentProps={{ position: "start" }}
            KeyboardButtonProps={{ size: "small" }}
            TextFieldComponent={AutoCompleteOffTextField}
            value={values.date}
            onChange={onCurrentDayChange}
            disabled={loading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LoadableSelect
            fullWidth
            label="Class Category"
            margin="dense"
            variant="outlined"
            request={offeringCategoryRequest}
            getOptionLabel={(option) =>
              option && option.name ? option.name : ""
            }
            defaultValue
            value={values.offeringCategory}
            onChange={(event, value) => {
              onValueChange("offeringCategory", value);
            }}
            disabled={loading}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <LoadableSelect
            fullWidth
            label="Branch"
            variant="outlined"
            margin="dense"
            request={!userLoading && storeBranchRequest}
            getOptionLabel={(option) =>
              option && option.name ? option.name : ""
            }
            value={values.storeBranch}
            onChange={(event, value) => {
              onValueChange("storeBranch", value);
            }}
            disabled={userLoading}
          />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default FilterForm;

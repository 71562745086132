import React from "react";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import {
  InputLabel,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  FormControl,
} from "@material-ui/core";
import axios from "axios";

function EmailDialog(props) {
  const { open, onClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [mailingList, setMailingList] = React.useState([]);

  // settings for filtering the recipients
  const [activePackage, setActivePackage] = React.useState(false);
  const [inactivePackage, setInactivePackage] = React.useState(false);
  const [branch, setBranch] = React.useState(null);
  const [template, setTemplate] = React.useState(null);

  const [clients, setClients] = React.useState([]);
  const [branches, setBranches] = React.useState([]);
  const [templates, setTemplates] = React.useState([]);

  const handleToggleRecipient = (email) => {
    if (mailingList.includes(email)) {
      const newMailingList = mailingList.filter((mail) => mail !== email);
      setMailingList(newMailingList);
    } else {
      setMailingList([...mailingList, email]);
    }
  };

  const handleAddAll = () => {
    if (mailingList.length === clients.length) setMailingList([]);
    else setMailingList(clients.map((client) => client.email));
  };

  const clearOptions = () => {
    setActivePackage(false);
    setInactivePackage(false);
    setBranch(null);
    setTemplate(null);
  };

  const sendEmails = async () => {
    try {
      if (mailingList.length === 0) throw "No recipient";
      if (!template) throw "No template selected";
      let templateContent = await axios.get(`/api/mailTemplates/${template}`);
      await axios.post("/api/sendEmail", {
        mailingList: mailingList,
        template: templateContent.data.content,
        subject: templateContent.data.subject,
      });
      //   await axios.get("/api/sendEmail");
    } catch (error) {
      //console.log(error);
    }
  };

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      let retrieveData = async () => {
        let returnClients = await axios.get(
          `/api/customerTiers?activePackage=${activePackage}&inactivePackage=${inactivePackage}&branch=${branch}`
        );
        setClients(returnClients.data.content);
        if (branches.length === 0) {
          let returnBranches = await axios.get("/api/storeBranches");
          setBranches(returnBranches.data.content);
        }
        if (templates.length === 0) {
          let returnTemplates = await axios.get("/api/mailTemplates");
          setTemplates(returnTemplates.data.content);
        }
      };
      retrieveData().then(() => setLoading(false));
    }
    if (!open) {
      clearOptions();
    }
  }, [activePackage, inactivePackage, branch, open]);

  return (
    <React.Fragment>
      <LoadableDialog
        open={open}
        onClose={onClose}
        loading={loading}
        title="Email to clients"
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={activePackage}
              onChange={() => setActivePackage(!activePackage)}
              name="activePackage"
              color="primary"
            />
          }
          label="Have active package"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={inactivePackage}
              onChange={() => setInactivePackage(!inactivePackage)}
              name="inactivePackage"
              color="primary"
            />
          }
          label="Have inactive package"
        />
        <br />
        <FormControl style={{ marginBottom: 5, minWidth: 120 }}>
          <InputLabel>Branch</InputLabel>
          <Select
            value={branch}
            onChange={(event) => setBranch(event.target.value)}
          >
            {branches.map((b) => (
              <MenuItem value={b.id}>{b.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ float: "right" }}>
          <Button onClick={() => clearOptions()}>Clear</Button>
        </div>
        <Table style={{ marginBottom: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={mailingList.length === clients.length}
                  onChange={handleAddAll}
                />
              </TableCell>
              <TableCell padding="default">Name</TableCell>
              <TableCell padding="default">Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((client) => {
              return (
                <React.Fragment>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={mailingList.includes(client.email)}
                        onChange={() => handleToggleRecipient(client.email)}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      {client.firstName} {client.lastName}
                    </TableCell>
                    <TableCell component="th" scope="row" padding="default">
                      {client.email}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <FormControl style={{ marginBottom: 5, minWidth: 300 }}>
          <InputLabel>Select email template</InputLabel>
          <Select
            value={template}
            onChange={(event) => setTemplate(event.target.value)}
          >
            {templates.map((t) => (
              <MenuItem value={t.id}>{t.subject}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ float: "right" }}>
          <span style={{ marginRight: 5 }}>
            Total Recipients: {mailingList.length}
          </span>
          <Button
            color="primary"
            variant="contained"
            onClick={() => sendEmails()}
          >
            Send
          </Button>
        </div>
      </LoadableDialog>
    </React.Fragment>
  );
}

export default EmailDialog;

import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { coreCreateParams } from "components/CoreCreateParams";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    ...theme.typography.h5,
  },
}));

export default function AttributeFormDialog(props) {
  const { title, open, loading, onClose, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FormikTextField
              required
              fullWidth
              label="Branch Name"
              name="name"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              label="Contact Phone"
              name="contactPhone"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          {/* <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Moblie Phone"
              name="mobilePhone"
              variant="outlined"
              margin="dense"
            />
          </Grid> */}
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              label="Address"
              name="address"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          {/*
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="City"
              name="city"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="State or Province"
              name="stateOrProvince"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <FormikTextField
              fullWidth
              label="Country"
              name="country"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Latitude"
              name="latitude"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              label="Longitude"
              name="longitude"
              variant="outlined"
              margin="dense"
            />
          </Grid>
          <Grid item xs={12}>
            <div className="p-2">
              <strong>To find the latitude and longitude: </strong>
              <br />
              1. Please enter the address above to{" "}
              <Link href="https://www.google.com/maps">Google Maps</Link>
              <br />
              2. Click the marker on the map and right click to select "What's
              here?" <br />
              3. Copy the two numbers in the pop-up box below. (Left: latitude,
              Right: longitude) e.g. Latitude: 22.302889, Longitude: 114.233024
              of 藍田分店
              <br />
            </div>
          </Grid>
              */}
          {title === "Edit Branch" && (
            <Grid item md={6} xs={12}>
              <FormikSwitch
                name="status"
                label="Open?"
                description="Indicate if this branch is open"
                FormLabelProps={{
                  className: classes.sectionLabel,
                }}
              />
            </Grid>
          )}
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(50, "No more than 50 characters long")
    .required("Required"),
  contactPhone: Yup.string()
    .min(8, "No less than 8 characters long")
    .max(8, "No more than 8 characters long")
    .matches(/^[0-9]*$/, "Please enter correct phone number"),
  // mobilePhone: Yup.string()
  //   .min(8, "No less than 8 characters long")
  //   .max(8, "No more than 8 characters long")
  //   .matches(/^[0-9]*$/, "Please enter correct phone number"),
});

export const initialValues = {
  //id: null,
  name: "",
  address: "",
  //street: "",
  //city: "",
  //stateOrProvince: "",
  //country: "",
  contactPhone: "",
  //mobilePhone: "",
  //whatsapp: "",
  status: true,
  //latitude: "0.0",
  //longitude: "0.0",
};

export const createParams = (values) => {
  const editableField = [
    "name",
    "address",
    "contactPhone",
    //"mobilePhone",
    "status",
  ];
  const params = coreCreateParams(editableField, values);

  /*
  values.name && params.append("name", values.name);
  values.street && params.append("street", values.street);
  values.city && params.append("city", values.city);
  values.stateOrProvince && params.append("stateOrProvince", values.stateOrProvince);
  values.country && params.append("country", values.country);
  values.contactPhone && params.append("contactPhone", values.contactPhone);
  values.whatsapp && params.append("whatsapp", values.whatsapp);
  values.status && params.append("status", values.status);
  values.latitude && params.append("latitude", values.latitude);
  values.longitude && params.append("longitude", values.longitude);
*/
  return params;
};

import React, { useState, useEffect, Fragment } from "react";
import { useFormikContext } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles((theme) => ({
  root: {
    //   width: 500,
    //   '& > * + *': {
    //     marginTop: theme.spacing(3),
    //   },
  },
}));

const FormikMultiReferenceField = (props) => {
  const {
    name,
    label,
    apiUrl,
    apiParams,
    component: Component,
    getOptionLabel,
    getOptionValue,
    getOptionSelected,
    onChange,
    freeSolo,
    editName,
    edit,
    ...rest
  } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    inputProps,
    setFieldValue,
  } = useFormikContext();

  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [filteringParams, setFilteringParams] = useState(apiParams || {});
  const [valuesHash, setValuesHash] = useState("");

  const getOptionItemValue = (option) => {
    let value = null;

    if (getOptionValue) value = getOptionValue(option);

    return value || option?.id || option;
  };

  const getOptionItemLabel = (option) => {
    let value = null;

    if (getOptionLabel) value = getOptionLabel(option);

    return value || option?.name || option?.id || "";
  };

  useEffect(() => {
    if (apiUrl) {
      setLoaded(false);

      let params = {};
      if (typeof apiParams === "object") {
        params = Object.assign({}, filteringParams);
        if (!params.current) delete params.current;
      }

      axios
        .get(apiUrl, { params: params })
        .then((response) => {
          let options = response.data || [];

          setOptions(options);
          setLoaded(true);
        })
        .then((error) => {
          setLoaded(true);
        });
    }
  }, [apiUrl, filteringParams]);

  useEffect(() => {
    if (valuesHash != JSON.stringify(values[name])) {
      setFilteringParams({
        ...filteringParams,
        current: getOptionItemValue(_.get(values, name)),
      });

      setValuesHash(JSON.stringify(values[name]));
    }

    if (values[name].length > 0) {
      setSelectedOption(values[name]);
    } else {
      setSelectedOption([]);
    }
  }, [options, values]);

  const Field = Component || Autocomplete;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const classes = useStyles();

  //console.log(selectedOption);
  return (
    <FormControl
      variant={rest.variant || "outlined"}
      margin={rest.margin || "dense"}
      required={props.required || false}
      fullWidth
      className={classes.root}
    >
      <Field
        multiple
        style={{ marginTop: "-8px" }}
        id={rest.id || name}
        labelId={(rest.id || name) + "-label"}
        fullWidth
        filterSelectedOptions
        freeSolo={false}
        name={name + "-multiple"}
        label={label}
        options={options}
        value={selectedOption}
        // getOptionSelected={(option) =>
        //   getOptionItemValue(option) == getOptionItemValue(_.get(values, name))
        // }
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionItemLabel}
        // renderOption={(option, { selected }) => (
        //     <React.Fragment>
        //         <Checkbox
        //             icon={icon}
        //             checkedIcon={checkedIcon}
        //             style={{ marginRight: 8 }}
        //             checked={values[name]?.includes(getOptionItemValue(option))}
        //         />
        //         {option.lastnameTc + option.firstnameTc}
        //     </React.Fragment>
        // )}
        onChange={(event, value, reason) => {
          let selected = value.map((v) => getOptionItemValue(v));
          //console.log(value);
          //etSelectedOption(value);
          //setFieldValue(name + "-multiple", value);
          setFieldValue(name, value);
          //onChange && onChange(event, value);
        }}
        onBlur={handleBlur}
        disabled={!loaded || rest.disabled || isSubmitting}
        disableClearable={false}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helpertext={_.get(touched, name) && _.get(errors, name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            required={props.required || false}
            variant="outlined"
            margin={rest.margin || "dense"}
            error={Boolean(_.get(touched, name) && _.get(errors, name))}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {!loaded ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginRight: -27 }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </Fragment>
              ),
            }}
          />
        )}
        {...rest}
      ></Field>
      {/* <Autocomplete
                multiple
                id="checkboxes-tags-demo"
                options={options}
                disableCloseOnSelect
                getOptionLabel={getOptionLabel}
                renderOption={(option, { selected }) => (
                    <React.Fragment>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.lastnameTc + option.firstnameTc}
                    </React.Fragment>
                )}
                renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Checkboxes" placeholder="Favorites" />
                )}
            />*/}
      {Boolean(_.get(touched, name) && _.get(errors, name)) && (
        <FormHelperText error={true}>
          {_.get(touched, name) && _.get(errors, name)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikMultiReferenceField;

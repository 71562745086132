import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import { CUSTOMER_USER_MANAGEMENT_PATH } from "../../../constants/route.constant";
import IconSubTitle from "components/IconSubTitle";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";

const ManagementPageHeader = (props) => {
  const { onCreate, customerId, parent } = props;

  const { isEditCustomerUserAllowed } = useAccessControl();

  return (
    <React.Fragment>
      <PageHeader
        section="Client Management"
        title="Package Plans Payment Record"
        right={
          isEditCustomerUserAllowed() ? (
            <Button color="secondary" variant="contained" onClick={onCreate}>
              Add Payment Record
            </Button>
          ) : null
        }
        breadcrumbs={[
          {
            name: "Clients",
            link: CUSTOMER_USER_MANAGEMENT_PATH,
          },
          {
            name: "Package Plan",
            link: `/customerUsers/${customerId}/PackagePlans`,
          },
        ]}
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: PersonOutlinedIcon,
                  description:
                    parent.customerUser &&
                    parent.customerUser.firstName &&
                    parent.customerUser.lastName
                      ? parent.customerUser.firstName +
                        " " +
                        parent.customerUser.lastName
                      : parent.customerUser.lastName,
                },
                {
                  icon: AccountBalanceWalletOutlinedIcon,
                  description: "",
                },
              ]}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default ManagementPageHeader;

import React, { useEffect, useState } from "react";
import { makeStyles, Typography, Divider, Button } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikOfferingCategorySelect from "../../../components/inputs/FormikOfferingCategorySelect";
import { useFormikContext } from "formik";
import FormikPackagePlanSelect from "components/inputs/FormikPackagePlanSelect";
import FormikStoreBranchSelect from "components/inputs/FormikStoreBranchSelect";
import FormikInstructorSelect from "components/inputs/FormikInstructorSelect";
import FormikKeyboardTimePicker from "components/inputs/FormikKeyboardTimePicker";
import FormikKeyboardDatePicker from "components/inputs/FormikKeyboardDatePicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { coreCreateParams } from "components/CoreCreateParams";
import moment from "moment";
import FormikCustomerUserSelect from "components/inputs/FormikCustomerUserSelect";
import FormikReferenceField from "components/inputs/FormikReferenceField";
import axios from "axios";
import FormikSelectField from "components/inputs/FormikSelectField";

const useStyles = makeStyles((theme) => ({
  form: {
    width: 800,
  },
  editorLabel: {
    ...theme.typography.h5,
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  const [customer, setCustomer] = useState(null);
  const [date, setDate] = useState(null);
  const [categories, setCategories] = useState(null);
  const [instructors, setInstructor] = useState(null);

  const [packagePlanData, setPackagePlanData] = useState(null);
  const [packagePlanOptions, setPackagePlanOptions] = useState([]);
  const [storeBranchOptions, setStoreBranchOptions] = useState([]);

  const handleCustomerChange = (e, v) => {
    setCustomer(v);
    setFieldValue("packagePlan", null);
    setFieldValue("offeringCategory", null);
  };

  const handleDateChange = (e, v) => {
    setDate(v);
    setFieldValue("packagePlan", null);
    setFieldValue("offeringCategory", null);
  };

  const handlePackagePlanChange = (e, v) => {
    setFieldValue("packagePlan", e.target.value);
    setFieldValue("offeringCategory", null);

    let selectedPackagePlan =
      packagePlanData &&
      packagePlanData.filter((item) => item.id === e.target.value);
    if (
      selectedPackagePlan &&
      selectedPackagePlan.length > 0 &&
      selectedPackagePlan[0].allOfferingCategoryApplied === true
    ) {
      axios.get("/api/offeringCategories/variants/references").then((res) => {
        let data = res.data;
        let options = [];
        data.map((item) => options.push({ value: item.id, label: item.name }));
        setCategories(options);
      });
    } else if (
      selectedPackagePlan &&
      selectedPackagePlan.length > 0 &&
      selectedPackagePlan[0].allOfferingCategoryApplied === false
    ) {
      let options = [];
      selectedPackagePlan[0].offeringCategories.map((item) =>
        options.push({ value: item.id, label: item.name })
      );
      setCategories(options);
    }
  };

  const handleInstructorChange = (e, v) => {
    setInstructor(v);
  };

  useEffect(() => {
    if (customer && date) {
      axios
        .get(
          "/api/purchasedPackagePlans/customerUsers/" +
            customer.id +
            "/available?date=" +
            moment(date).format("YYYY-MM-DD") +
            "&page=0&pageSize=100"
        )
        .then((res) => {
          if (!res.data.empty) {
            let data = res.data.content;
            let options = [];
            data.map((item) =>
              options.push({
                value: item.id,
                label: item.packagePlanName,
              })
            );
            setPackagePlanData(data);
            setPackagePlanOptions(options);
          } else {
            setPackagePlanOptions(null);
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    if (instructors) {
      axios
        .get("/api/staffUsers/" + instructors.id + "/storeBranches")
        .then((res) => {
          let data = res.data;
          let options = [];
          data.map((item) =>
            options.push({ value: item.id, label: item.name })
          );
          setStoreBranchOptions(options);
        })
        .catch((err) => {
          //console.log(err);
        });
    }
  }, [customer, date, instructors]);

  return (
    <FormikFormDialog
      {...rest}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
      paperClassName={classes.form}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5">
              1. Please Select Customer & Date
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikReferenceField
              required
              name="customer"
              label="Customer"
              apiUrl={"/api/customerUsers/variants/references"}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              getOptionValue={(option) => option?.id}
              onChange={handleCustomerChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              disablePast
              name="classSessionDate"
              label="Date"
              onChange={handleDateChange}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5">
              2. Please Select Customer's Purchased PackagePlan
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikSelectField
              required
              disabled={!customer || !date}
              name="packagePlan"
              label="Package Plan"
              options={packagePlanOptions}
              onChange={handlePackagePlanChange}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikSelectField
              required
              disabled={!customer || !date || values.packagePlan === null}
              name="offeringCategory"
              label="Class Category"
              options={categories}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5">
              3. Please Select Instructor & Store Branch
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikReferenceField
              required
              name="instructorStaffUser"
              label="Instructor"
              apiUrl={"/api/staffUsers/instructors/variants/references"}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              getOptionValue={(option) => option?.id}
              onChange={handleInstructorChange}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormikSelectField
            required
            disabled={!instructors}
            name="storeBranch"
            label="Store Branch"
            options={storeBranchOptions}
          />
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <Typography variant="h5">4. Please Select Time Session</Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardTimePicker
              required
              name="startTime"
              label="Start Time"
              minutesStep={15}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardTimePicker
              required
              name="endTime"
              label="End Time"
              minutesStep={15}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider} />
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              name="remarks"
              label="Remarks"
              variant="outlined"
              margin="dense"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  packagePlan: Yup.string()
    .nullable(true)
    .required("Required"),
  storeBranch: Yup.string()
    .nullable(true)
    .required("Required"),
  instructorStaffUser: Yup.object()
    .nullable(true)
    .required("Required"),
  offeringCategory: Yup.string()
    .nullable(true)
    .required("Required"),
  classSessionDate: Yup.date()
    .nullable(true)
    .required("Required"),
  startTime: Yup.string()
    .nullable(true)
    .required("Required"),
  endTime: Yup.string()
    .nullable(true)
    .required("Required"),
});

export const initialValues = {
  customer: null,
  packagePlan: "",
  storeBranch: "",
  instructorStaffUser: null,
  offeringCategory: "",
  classSessionDate: null,
  startTime: null,
  endTime: null,
  remarks: "",
};

export const createParams = (values) => {
  const editableField = [
    "purchasedPackagePlan",
    "storeBranch",
    "instructorStaffUser",
    "offeringCategory",
    "classSessionDate",
    "startTime",
    "endTime",
    "remarks",
  ];

  values.purchasedPackagePlan = values.packagePlan;
  values.instructorStaffUser = values.instructorStaffUser.id;
  values.classSessionDate = moment(values.classSessionDate).format(
    "YYYY-MM-DD"
  );
  values.startTime = moment(values.startTime).format("HH:mm");
  values.endTime = moment(values.endTime).format("HH:mm");

  delete values.customer;
  delete values.packagePlan;

  const params = coreCreateParams(editableField, values);
  return params;
};

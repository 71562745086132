import React from "react";
import ListingTable from "./components/ListingTable";
import DisplayDialog from "./components/DisplayDialog";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import FilterForm from "./components/FilterForm";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import ManagementPageHeader from "./components/ManagementPageHeader";
import SubEntityManagement from "../../components/SubEntityManagement";
import axios from "axios";

const CustomerUserPackagePlanManagement = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return (
    <SubEntityManagement
      pageHeader={ManagementPageHeader}
      filterForm={FilterForm}
      createFormDialog={CreateFormDialog}
      editFormDialog={EditFormDialog}
      displayDialog={DisplayDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      listingTable={ListingTable}
      request={(params) => {
        return axios.get(`/api/purchasedPackagePlans/customerUsers/${id}`, {
          params: {
            ...params,
          },
        });
      }}
      parentApi={`/api/customerUsers/${id}`}
    />
  );
};

export default CustomerUserPackagePlanManagement;

import React, { useLayoutEffect, useState } from "react";
import axios from "axios";
import EntityManagement from "./EntityManagement";
import LoadingState from "./LoadingState";
import LoadErrorPage from "./LoadErrorPage";

const SubEntityManagement = (props) => {
  const { parentApi, parentRequest, ...rest } = props;

  const [parent, setParent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [reload, setReload] = useState(false);
  const [needInstructor, setNeedInstructor] = useState(false);

  useLayoutEffect(() => {
    let mounted = true;

    setLoading(true);
    setLoadingError(false);

    const result = parentRequest ? parentRequest() : axios.get(parentApi);

    result
      .then((response) => {
        setNeedInstructor(response.data["showInstructorOption"]);
        mounted && setParent(response.data);
        mounted && setLoadingError(false);
      })
      .catch(() => {
        mounted && setLoadingError(true);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      mounted = false;
    };
  }, [parentApi, parentRequest, reload]);

  if (loading) {
    return <LoadingState />;
  }

  if (loadingError) {
    return (
      <LoadErrorPage
        buttonText="Reload"
        ButtonProps={{
          onClick: () => setReload(!reload),
        }}
      />
    );
  }

  return (
    <EntityManagement
      {...rest}
      parent={parent}
      needInstructor={needInstructor}
      //onLoadPageHeader={load => {if(!reload) setReload(true); else setReload(false);}}
    />
  );
};

export default SubEntityManagement;

import React, { useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import * as Yup from "yup";
//import { PAYMENT_OPTIONS } from "../../../constants/app.constant";
import FormikRadioGroup from "../../../components/inputs/FormikRadioGroup";
import FormikStaffUserSelect from "components/inputs/FormikStaffUserSelect";
import FormikTextField from "components/inputs/FormikTextField";
import { coreCreateParams } from "components/CoreCreateParams";
import FormikReferenceField from "components/inputs/FormikReferenceField";
import FormikMultiReferenceField from "components/inputs/FormikMultiReferenceField";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    ...theme.typography.h5,
  },
}));

const EditForm = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const classes = useStyles();

  const { resetForm, values } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);
    resetForm({ values: initialValues });
    axios
      .get(`/api/purchasedPackagePlans/${entity.id}`)
      .then((response) => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch((error) => {
        // onClose();
        // notifyApiError(
        //   error.response.status,
        //   {
        //     403: {
        //       message: `Access denied to package plan "${entity.packagePlan.name}"`,
        //       variant: "error",
        //     },
        //   },
        //   {
        //     message: `Unable to fetch package plan "${entity.packagePlan.name}"`,
        //     variant: "error",
        //   }
        // );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <FormikFormDialog
      {...rest}
      title={
        "Edit " +
        (values.type === "PACKAGE_PLAN"
          ? "Package Plan (" + values.packagePlanName + ")"
          : "Membership")
      }
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              required
              name="effectiveDate"
              label="Effective Date"
            />
          </Grid>
          {values.type === "PACKAGE_PLAN" && (
            <Grid item md={6} xs={12}>
              <FormikKeyboardDatePicker
                required
                disablePast
                name="expiryDate"
                label="Expiration Date"
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <FormikMultiReferenceField
              fullWidth
              name="salesStaffUsers"
              label="Sales Staff *"
              margin="dense"
              variant="outlined"
              apiUrl={"/api/staffUsers/variants/references"}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikReferenceField
              required
              label="Incharge Staff"
              name="inChargeStaffUser"
              apiUrl={"/api/staffUsers/variants/references"}
              getOptionLabel={(option) =>
                option.firstName + " " + option.lastName
              }
              getOptionValue={(option) => option?.id}
              edit
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              name="deposit"
              label="Deposit"
              margin="dense"
              variant="outlined"
              type="number"
            />
          </Grid>
          {values.type === "MEMBERSHIP" && (
            <Grid item md={6} xs={12}>
              <FormikTextField
                required
                fullWidth
                name="totalAmount"
                label="Total Amount"
                margin="dense"
                variant="outlined"
                type="number"
              />
            </Grid>
          )}
          <Grid item md={6} xs={12}>
            <FormikTextField
              fullWidth
              name="remarks"
              label="Remarks"
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="active"
              label="Toggle Status"
              description="Indicate if this package plan / membership is still used"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="fsDone"
              label="FS"
              description="Indicate if this FS is still used"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
};

const validationSchema = Yup.object().shape({
  quantity: Yup.string().when("type", {
    is: "PACKAGE_PLAN",
    then: Yup.string().required("Required"),
  }),
  effectiveDate: Yup.date()
    .nullable(true)
    .required("Required"),
  expiryDate: Yup.date().nullable(true),
  salesStaffUsers: Yup.array()
    .min(1, "Required")
    .required("Required"),
  deposit: Yup.string().required("Required"),
  inChargeStaffUser: Yup.object()
    .nullable(true)
    .when("type", {
      is: "PACKAGE_PLAN",
      then: Yup.string().required("Required"),
    }),
  totalAmount: Yup.string().when("type", {
    is: "MEMBERSHIP",
    then: Yup.string().required("Required"),
  }),
});

const validate = (values) => {
  const errors = {};

  if (values.effectiveDate && values.expirationDate) {
    if (values.expirationDate.isBefore(values.effectiveDate)) {
      errors.endDate = "Cannot be earlier than effective date";
    }
  }

  return errors;
};

const initialValues = {
  effectiveDate: null,
  expirationDate: null,
  salesStaffUsers: [],
  quantity: "",
  deposit: "",
  totalAmount: "",
  inChargeStaffUser: "",
  active: true,
  fsDone: false,
  remarks: "",
};

const createParams = (values) => {
  const editableField = [
    "effectiveDate",
    "expiryDate",
    "salesStaffUsers",
    "deposit",
    "totalAmount",
    "inChargeStaffUser",
    "active",
    "fsDone",
    "remarks",
  ];

  values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
  values.expiryDate = values.expiryDate
    ? moment(values.expiryDate).format("YYYY-MM-DD")
    : null;
  values.inChargeStaffUser = values.inChargeStaffUser
    ? values.inChargeStaffUser.id
    : null;

  let selectedSales = [];
  values.salesStaffUsers &&
    values.salesStaffUsers.map((item) => selectedSales.push(item.id));
  values.salesStaffUsers = selectedSales;
  values.quantity = values.quantity ? values.quantity : null;

  const params = coreCreateParams(editableField, values);
  return params;
};

const FormikEditForm = (props) => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating package plan "${entity.packagePlan.name}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/purchasedPackagePlans/${values.id}`, createParams(values))
      .then(() => {
        notify(`Package plan "${entity.packagePlan.name}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning",
            },
            403: {
              message: `Access denied to update package plans`,
              variant: "error",
            },
          },
          {
            message: `Unable to update package plan "${entity.packagePlan.name}"`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = (receivedValues) => {
  receivedValues.effectiveDate &&
    (receivedValues.effectiveDate = moment(
      receivedValues.effectiveDate,
      "YYYY-MM-DD"
    ));
  receivedValues.expirationDate &&
    (receivedValues.expirationDate = moment(
      receivedValues.expirationDate,
      "YYYY-MM-DD"
    ));
  receivedValues.inChargeStaffUser =
    receivedValues.inChargeStaffUser && receivedValues.inChargeStaffUser.id;

  let selectedSalesStaffUsers = [];
  if (receivedValues.salesStaffUsers) {
    receivedValues.salesStaffUsers.map((item) => {
      selectedSalesStaffUsers.push(item);
      receivedValues.salesStaffUsers = selectedSalesStaffUsers;
    });
  } else {
    receivedValues.salesStaffUsers = [];
  }
};

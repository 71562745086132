import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import { retrieveInstructorName } from "../../../utils/app.util";
import {
  TRANSCRIPT_DONE_BY_TYPE,
  TRANSCRIPT_STATUS_VALUE_DISPLAY_MAPPING,
} from "../../../constants/app.constant";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    position: "relative",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    whiteSpace: "nowrap",
  },
  columnAction: {
    minWidth: 120,
  },
}));

const ListingTable = (props) => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Class Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Instructor</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Package Plan</TableCell>
                    <TableCell>Enrolled By</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map((entity) => (
                    <TableRow hover key={entity.id}>
                      <TableCell>
                        {entity.offeringScheduleSessionInfo &&
                          entity.offeringScheduleSessionInfo.offering &&
                          entity.offeringScheduleSessionInfo.offering.name}
                      </TableCell>
                      <TableCell>
                        {entity.offeringScheduleSessionInfo &&
                          entity.offeringScheduleSessionInfo.day &&
                          moment(entity.offeringScheduleSessionInfo.day).format(
                            "YYYY-MM-DD"
                          )}
                      </TableCell>
                      <TableCell>
                        {entity.offeringScheduleSessionInfo &&
                          entity.offeringScheduleSessionInfo.startTime &&
                          moment(
                            entity.offeringScheduleSessionInfo.startTime,
                            "HH:mm"
                          ).format("HH:mm")}
                      </TableCell>
                      <TableCell>
                        {entity.offeringScheduleSessionInfo &&
                          entity.offeringScheduleSessionInfo.endTime &&
                          moment(
                            entity.offeringScheduleSessionInfo.endTime,
                            "HH:mm"
                          ).format("HH:mm")}
                      </TableCell>
                      <TableCell>
                        {entity.offeringScheduleSessionInfo &&
                          entity.offeringScheduleSessionInfo.instructor &&
                          retrieveInstructorName(
                            entity.offeringScheduleSessionInfo.instructor
                          )}
                      </TableCell>
                      <TableCell>
                        {entity.status &&
                          TRANSCRIPT_STATUS_VALUE_DISPLAY_MAPPING[
                            entity.status
                          ]}
                      </TableCell>
                      <TableCell>
                        {entity.purchasedPackagePlan &&
                          entity.purchasedPackagePlan.packagePlanName}
                      </TableCell>
                      <TableCell>
                        {entity.doneByType &&
                          TRANSCRIPT_DONE_BY_TYPE[entity.doneByType]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageNumberChange}
            onRowsPerPageChange={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;

import React from "react";
import { Formik } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { PACKAGE_PLAN_PAYMENT_METHOD } from "constants/app.constant";
import FormikTextField from "components/inputs/FormikTextField";
import { coreCreateParams } from "components/CoreCreateParams";
import { useParams } from "react-router";
import FormikSelectField from "components/inputs/FormikSelectField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
}));

const CreateForm = (props) => {
  const { open, onClose, onEnroll, parent, ...rest } = props;

  return (
    <div>
      <FormikFormDialog
        {...rest}
        title={"Add Payment Record"}
        open={open}
        onClose={onClose}
        loading={false}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <FormikKeyboardDatePicker
                required
                disablePast
                name="dueDate"
                label="Due Date"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikKeyboardDatePicker
                name="paymentDate"
                label="Payment Date"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSelectField
                name="paymentMethod"
                label="Payment Method"
                options={PACKAGE_PLAN_PAYMENT_METHOD}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikTextField
                required
                fullWidth
                name="amount"
                label="Amount"
                margin="dense"
                variant="outlined"
                type="number"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <FormikTextField
                fullWidth
                name="remarks"
                label="Remarks"
                margin="dense"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </FormikFormDialog>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  paymentDate: Yup.date().nullable(true),
  dueDate: Yup.date()
    .nullable(true)
    .required("Required"),
  amount: Yup.string()
    .required("Required")
    .nullable(),
  remarks: Yup.string().nullable(),
});

const validate = (values) => {
  const errors = {};

  // if (values.effectiveDate && values.expirationDate) {
  //   if (values.expirationDate.isBefore(values.effectiveDate)) {
  //     errors.endDate = "Cannot be earlier than effective date";
  //   }
  // }

  return errors;
};

const initialValues = {
  paymentDate: null,
  dueDate: null,
  paymentMethod: null,
  amount: "",
  remarks: "",
};

const createParams = (values) => {
  const editableField = [
    "paymentDate",
    "dueDate",
    "paymentMethod",
    "amount",
    "remarks",
  ];

  values.paymentDate =
    values.paymentDate && moment(values.paymentDate).format("YYYY-MM-DD");
  values.dueDate =
    values.dueDate && moment(values.dueDate).format("YYYY-MM-DD");

  const params = coreCreateParams(editableField, values);
  return params;
};

export default function(props) {
  const { onOpen, onClose, onSuccess, parent, ...rest } = props;
  const { packageId } = useParams();

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Adding payment record`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .post(`/api/purchasedPackagePlans/${packageId}/paymentRecords`, [
        createParams(values),
      ])
      .then(() => {
        notify(`Payment record added`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning",
            },
            403: {
              message: `Access denied to add payment record`,
              variant: "error",
            },
          },
          {
            message: `Unable to add payment record`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <CreateForm {...rest} parent={parent} onClose={onClose} />
    </Formik>
  );
}

import React, { useState } from "react";
import { FormControl, FormControlLabel, Checkbox } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";

const FormikCheckboxField = (props) => {
  const {
    name,
    icon,
    className,
    checkedIcon,
    label,
    value,
    checked,
    indeterminate,
    color,
    size,
    labelPlacement,
    onChange,
    disabled,
    helperComponent: HelperComponent,
    component: Component,
    options,
    ...rest
  } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    inputProps,
    setFieldValue,
  } = useFormikContext();

  const Field = Component || Checkbox;
  const [optionChecked, setOptionChecked] = useState(false);

  return (
    <FormControl className={className}>
      <FormControlLabel
        labelPlacement={labelPlacement}
        //value={value || true}
        control={
          <Checkbox
            disabled={disabled}
            icon={icon}
            checkedIcon={checkedIcon}
            name={name}
            value={value || true}
            checked={
              _.get(values, name) ||
              optionChecked ||
              (checked !== undefined && checked)
            }
            indeterminate={indeterminate || false}
            color={color || "primary"}
            onChange={
              onChange
                ? (event, value) => {
                    setOptionChecked(event.target.checked);
                    onChange && onChange(event, value);
                  }
                : handleChange
            }
            size={size}
          />
        }
        label={label}
      />
      {HelperComponent && <HelperComponent />}
      {Boolean(_.get(touched, name) && _.get(errors, name)) && (
        <FormHelperText error={true}>
          {_.get(touched, name) && _.get(errors, name)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikCheckboxField;

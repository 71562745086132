import React, { useState, useEffect, useLayoutEffect, Fragment } from "react";
import { useFormikContext } from "formik";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import axios from "axios";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { normalizeUnits } from "moment";

const FormikReferenceField = (props) => {
  const {
    name,
    label,
    apiUrl,
    apiParams,
    component: Component,
    getOptionLabel,
    getOptionValue,
    styles,
    onChange,
    freeSolo,
    edit,
    noApiOptions,
    ...rest
  } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    inputProps,
    setFieldValue,
  } = useFormikContext();

  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteringParams, setFilteringParams] = useState(apiParams || {});
  const [valuesHash, setValuesHash] = useState("");

  const getOptionItemValue = (option) => {
    let value = null;
    if (getOptionValue) value = getOptionValue(option);

    return value || option?.id || option;
  };

  const getOptionItemLabel = (option) => {
    let value = null;

    if (getOptionLabel) value = getOptionLabel(option);

    return (
      value ||
      option?.nameTc ||
      option?.name ||
      option?.id ||
      (typeof option == "string" ? option : "")
    );
  };

  useEffect(() => {
    if (apiUrl) {
      setLoaded(false);

      let params = {};
      if (typeof apiParams === "object") {
        params = Object.assign({}, filteringParams);
        if (!params.current) delete params.current;
      }

      axios
        .get(apiUrl, { params: params })
        .then((response) => {
          if (response.data.empty == false) {
            let options = response.data?.content || response.data || [];
            setLoaded(true);
            if (edit) {
              if (getOptionItemValue(_.get(values, name))) {
                setSelectedOption(
                  options.find(
                    (option) =>
                      getOptionItemValue(option) ==
                      getOptionItemValue(_.get(values, name))
                  )
                );
              }
            }
          } else if (response.data.length >= 0) {
            let options = response.data?.content || response.data || [];
            setOptions(options);
            setLoaded(true);
            if (edit) {
              if (getOptionItemValue(_.get(values, name))) {
                setSelectedOption(
                  options.find(
                    (option) =>
                      getOptionItemValue(option) ==
                      getOptionItemValue(_.get(values, name))
                  )
                );
              }
            }
          }
        })
        .catch((error) => {
          setLoaded(true);
        });
    }
    if (noApiOptions) {
      setLoaded(true);
    }
  }, [apiUrl, filteringParams]);

  useEffect(() => {
    if (valuesHash != JSON.stringify(values[name])) {
      setLoaded(false);

      setFilteringParams({
        ...filteringParams,
        current: getOptionItemValue(_.get(values, name)),
      });

      setValuesHash(JSON.stringify(values[name]));
    }
  }, [options, values]);

  const Field = Component || Autocomplete;

  return (
    <FormControl
      variant={rest.variant || "outlined"}
      margin={rest.margin || "dense"}
      required={props.required || false}
      fullWidth
    >
      <Field
        noOptionsText={"No Option"}
        style={styles || { marginTop: "-8px" }}
        id={rest.id || name}
        labelid={(rest.id || name) + "-label"}
        fullWidth
        freeSolo={false}
        name={name}
        label={label}
        options={noApiOptions || options}
        value={selectedOption}
        getOptionSelected={(option) =>
          getOptionItemValue(option) == getOptionItemValue(_.get(values, name))
        }
        getOptionLabel={getOptionItemLabel}
        onChange={(event, value) => {
          setSelectedOption(value);
          setFieldValue(name, value);
          onChange && onChange(event, value);
        }}
        onBlur={handleBlur}
        disabled={!loaded || rest.disabled || isSubmitting}
        disableClearable={false}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helpertext={_.get(touched, name) && _.get(errors, name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={loaded ? label : "Loading " + label}
            required={props.required || false}
            variant="outlined"
            margin={rest.margin || "dense"}
            error={Boolean(_.get(touched, name) && _.get(errors, name))}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  {!loaded ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                      style={{ marginRight: -27 }}
                    />
                  ) : (
                    params.InputProps.endAdornment
                  )}
                </Fragment>
              ),
            }}
          />
        )}
        {...rest}
      ></Field>
      {Boolean(_.get(touched, name) && _.get(errors, name)) && (
        <FormHelperText error={true}>
          {_.get(touched, name) && _.get(errors, name)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikReferenceField;

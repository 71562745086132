import { grey } from "@material-ui/core/colors";

const white = "#FFFFFF";
const black = "#000000";

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: "#034dcc",
    main: "#034dcc",
    light: "#034dcc",
    "&:hover": {},
  },
  secondary: {
    contrastText: white,
    dark: "#034dcc",
    main: "#034dcc",
    light: "#034dcc",
    "&:hover": {},
  },
  error: {
    contrastText: white,
    dark: "#b20000",
    main: "#ed1c24",
    light: "#ff5f4e",
  },
  background: {
    default: grey[100],
    paper: white,
  },
};

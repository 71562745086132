import React from "react";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import FormikTextField from "../../../components/inputs/FormikTextField";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import { makeStyles } from "@material-ui/core";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import { coreCreateParams } from "components/CoreCreateParams";

const useStyles = makeStyles({
  form: {
    width: 400,
  },
});

export default function(props) {
  const { open, onClose, title, loading, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog
      {...rest}
      paperClassName={classes.form}
      title={title}
      open={open}
      loading={loading}
      onClose={onClose}
    >
      <Grid container spacing={1}>
        <Grid item md={12} xs={12}>
          <FormikTextField
            autoFocus
            required
            fullWidth
            label="Name"
            name="name"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        {/* <Grid item md={12} xs={12}>
          <FormikSwitch
              name="showInstructorOption"
              label="Instructor"
              description="Enable this will allow clients to hire instructor"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
        </Grid> */}
      </Grid>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(150, "Name cannot be longer than 150.")
    .required("Required"),
});

export const initialValues = {
  id: null,
  name: "",
  showInstructorOption: false,
};

export const createParams = (values) => {
  const editableField = ["name"];
  const params = coreCreateParams(editableField, values);

  //values.name && params.append("name", values.name);
  // values.showInstructorOption &&
  // params.append("showInstructorOption", values.showInstructorOption);

  return params;
};

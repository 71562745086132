import React from "react";
import ListingTable from "./components/ListingTable";
import ManagementPageHeader from "./components/ManagementPageHeader";
import SubEntityManagement from "../../components/SubEntityManagement";
import axios from "axios";

const TranscriptHistoryList = (props) => {
  const {
    match: {
      params: { id },
    },
  } = props;

  return (
    <SubEntityManagement
      pageHeader={ManagementPageHeader}
      listingTable={ListingTable}
      request={(params) => {
        return axios.get("/api/transcripts/resources", {
          params: {
            ...params,
            customerUser: id,
          },
        });
      }}
      parentApi={`/api/customerUsers/${id}`}
    />
  );
};

export default TranscriptHistoryList;

import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import securityService from "../../../services/security.service";
import { connect } from "react-redux";
import { Typography, Tooltip } from "@material-ui/core";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
//import { QrCodeDialog } from "./QrCodeDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
  },
  space: {
    flexGrow: 1,
  },
  name: {
    color: theme.palette.primary.contrastText,
  },
}));

const TopBar = (props) => {
  const { toggleNavOpen, className, personalSettings } = props;
  //const [qrCodeOpen, setQrCodeOpen] = useState(false);

  const classes = useStyles();

  // const [langAnchorEl, setLangAnchorEl] = useState(null);
  //
  // function handleLangClick(event) {
  //   setLangAnchorEl(event.currentTarget);
  // }
  //
  // function handleLangClose() {
  //   setLangAnchorEl(null);
  // }

  function logout() {
    securityService.logout(props.dispatch);
  }

  return (
    <AppBar className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <IconButton color="inherit" onClick={toggleNavOpen}>
          <MenuIcon />
        </IconButton>
        <div className={classes.space} />
        {/* <IconButton color="inherit" onClick={handleLangClick}>
          <LanguageIcon />
        </IconButton> */}
        {/* <Menu
          anchorEl={langAnchorEl}
          getContentAnchorEl={null}
          open={Boolean(langAnchorEl)}
          keepMounted
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          onClose={handleLangClose}
        >
          <MenuItem>English</MenuItem>
          <MenuItem>繁體中文</MenuItem>
        </Menu> */}

        <Typography variant="h6" className={classes.name}>
          {`${personalSettings.firstName}`}
        </Typography>
        {/* <Tooltip title="Scan QR Code">
          <IconButton
            edge="end"
            aria-haspopup="true"
            color="inherit"
            onClick={() => {
              setQrCodeOpen(true);
            }}
          >
            <CameraAltOutlinedIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Log Out">
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </Toolbar>
      {/* <QrCodeDialog
        open={qrCodeOpen}
        onClose={() => {
          setQrCodeOpen(false);
        }}
      /> */}
    </AppBar>
  );
};

const mapStateToProps = (state) => ({
  personalSettings: state.session.data.personalSettings,
});

export default connect(mapStateToProps)(TopBar);

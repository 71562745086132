import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import PerfectScrollbar from "react-perfect-scrollbar";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { Link } from "react-router-dom";
import HistoryIcon from "@material-ui/icons/History";
import SubEntityManagement from "components/SubEntityManagement";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    position: "relative",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    overflowX: "auto",
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    whiteSpace: "nowrap",
  },
  columnAction: {
    minWidth: 120,
  },
  button: {
    color: "blue",
  },
}));

const ListingTable = (props) => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    onViewLatestPackage,
    ...rest
  } = props;

  const classes = useStyles();

  const {
    isReadCustomerUserAllowed,
    isEditCustomerUserAllowed,
    isDeleteCustomerUserAllowed,
  } = useAccessControl();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Customer ID No.</TableCell>
                    <TableCell>First Name</TableCell>
                    <TableCell>Last Name</TableCell>
                    {/* <TableCell>Nick Name</TableCell> */}
                    <TableCell>Gender</TableCell>
                    <TableCell>Mobile Phone</TableCell>
                    <TableCell>Return Deposit</TableCell>
                    <TableCell className={classes.columnAction} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map((entity) => (
                    <TableRow hover key={entity.customerIdNo}>
                      <TableCell>{entity.customerIdNo}</TableCell>
                      <TableCell>{entity.firstName}</TableCell>
                      <TableCell>{entity.lastName}</TableCell>
                      {/* <TableCell>{entity.nickName}</TableCell> */}
                      <TableCell>{entity.gender}</TableCell>
                      <TableCell>{entity.mobilePhone}</TableCell>
                      <TableCell>{entity.returnDeposit}</TableCell>
                      {/* <TableCell>
                        {entity.latestExpirationPackagePlan ? (
                          <Button
                            className={classes.button}
                            onClick={() => onViewLatestPackage(entity)}
                          >
                            {entity.latestExpirationPackagePlan.packagePlanName}
                          </Button>
                        ) : (
                          "No Package Founded"
                        )}
                      </TableCell>
                      <TableCell>{entity.membershipTierName}</TableCell> */}
                      <TableCell align="right">
                        {isEditCustomerUserAllowed() && (
                          <Link to={`/customerUsers/${entity.id}/packagePlans`}>
                            <Tooltip
                              title="Package Plan / Membership"
                              placement="bottom"
                            >
                              <IconButton color="default" size="small">
                                <FolderOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </Link>
                        )}
                        <Link
                          to={`/customerUsers/${entity.id}/purchasedPackagePlanUsages`}
                        >
                          <Tooltip
                            title="Package Plan / Membership Usage"
                            placement="bottom"
                          >
                            <IconButton color="default" size="small">
                              <AccountBalanceWalletOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Link>
                        {/* <Link to={`/trainingHistoryList/${entity.id}`}>
                          <Tooltip title="History" placement="bottom">
                            <IconButton color="default" size="small">
                              <HistoryIcon />
                            </IconButton>
                          </Tooltip>
                        </Link> */}
                        {isReadCustomerUserAllowed() && (
                          <Tooltip title="View" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onView(entity)}
                            >
                              <VisibilityOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isEditCustomerUserAllowed() && (
                          <Tooltip title="Edit" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onEdit(entity)}
                              disabled={
                                entity["deleting"] || entity["updating"]
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {isDeleteCustomerUserAllowed() && (
                          <Tooltip title="Delete" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onDelete(entity)}
                              disabled={
                                entity["deleting"] || entity["updating"]
                              }
                            >
                              <DeleteOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageNumberChange}
            onRowsPerPageChange={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;

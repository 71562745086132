import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Divider, Drawer, useMediaQuery } from "@material-ui/core";
import navigationConfig from "../../../config/navigation.config";
import Navigation from "../../../components/Navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "auto",
  },
  content: {
    padding: theme.spacing(2),
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logoImage: {
    width: 136,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}));

const NavBar = (props) => {
  const { className, navOpen, toggleNavOpen, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"), { noSsr: true });
  const drawerVariant = isMdDown ? "temporary" : "persistent";

  const navBarContent = (
    <div className={classes.content}>
      <div className={classes.logo}>
        <img
          className={classes.logoImage}
          src="/backend/static/images/logos/logo.png"
          alt="logo"
        />
      </div>
      <Divider className={classes.divider} />
      <Navigation
        config={navigationConfig}
        className={classes.navigation}
        component="div"
        LeafNodeProps={{
          onClick: drawerVariant === "temporary" ? toggleNavOpen : undefined,
        }}
      />
    </div>
  );

  return (
    <React.Fragment>
      <Drawer
        className={className}
        anchor="left"
        open={navOpen}
        onClose={toggleNavOpen}
        variant={drawerVariant}
      >
        <div {...rest} className={clsx(classes.root, className)}>
          {navBarContent}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default NavBar;

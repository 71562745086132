import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PerfectScrollbar from "react-perfect-scrollbar";
import moment from "moment";
import {
  TRANSCRIPT_DONE_BY_TYPE,
  TRANSCRIPT_STATUS_VALUE_DISPLAY_MAPPING,
} from "../../../constants/app.constant";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import { Link } from "react-router-dom";
import Checkbox from "@material-ui/core/Checkbox";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {},
  card: {
    position: "relative",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 700,
    overflowX: "auto",
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: "flex-end",
  },
  progress: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    whiteSpace: "nowrap",
  },
  columnAction: {
    minWidth: 120,
  },
  disableRow: {
    backgroundColor: "#c3c3c3",
    "&:hover": {
      backgroundColor: "#c3c3c3 !important",
    },
  },
}));

const ListingTable = (props) => {
  const {
    className,
    entities,
    onView,
    onEdit,
    onEditPackage,
    onEditPackageSuccess,
    onDelete,
    totalCount,
    pageNumber,
    pageSize,
    handlePageNumberChange,
    handlePageSizeChange,
    loading,
    needInstructor,
    ...rest
  } = props;

  const classes = useStyles();

  const handleAttendChange = (attended, absence, entity) => (e) => {
    attended = attended === 1 ? 0 : 1;
    //absence = attended === 1 ? 0 : absence;

    const params = new URLSearchParams();

    params.append("customerUser", entity.customerUser.id);
    params.append("offeringScheduleSession", entity.id);
    params.append("purchasedPackagePlan", entity.purchasedPackagePlan.id);
    params.append("attended", attended);
    // params.append("absence", absence);
    // params.append("purchasedPackagePlanItemId", entity.purchasedPackagePlanItemId);

    return axios
      .put(`/api/transcripts/actions/update/${entity.id}`, params)
      .then((response) => {
        onEditPackageSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
        }
      });
  };

  // const handleAbsenceChange = (attended, absence, entity) => (e) => {
  //   absence = absence === 1 ? 0 : 1;
  //   attended = absence === 1 ? 0 : attended;

  //   const params = new URLSearchParams();

  //   params.append("customerUser", entity.customerUser.id);
  //   params.append("offeringScheduleSession", entity.id);
  //   params.append("purchasedPackagePlan", entity.purchasedPackagePlan.id);
  //   params.append("attended", attended);
  //   params.append("absence", absence);
  //   // params.append("purchasedPackagePlanItemId", entity.purchasedPackagePlanItemId);

  //   return axios
  //     .put(`/api/transcripts/actions/update/${entity.id}`, params)
  //     .then((response) => {
  //       onEditPackageSuccess();
  //     })
  //     .catch((error) => {
  //       // Show the dialog with error messages if client side error, otherwise notify error messages
  //       if (error.response.status === 400) {
  //       }
  //     });
  // };

  // const { isEditOfferingScheduleAllowed } = useAccessControl();

  // console.log(entity.showInstructorOption);
  /*if(entity.showInstructorOption == true){
    tableCell = <TableCell>Need Instructor</TableCell>;
  }*/
  /*const handleShowInstructor = (entity) =>{
    if(entity.showInstructorOption == true){
      var tableCell;
      tableCell = <TableCell>Need Instructor</TableCell>;
      return tableCell;
    }
  }*/

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {totalCount} Records found. Page {pageNumber + 1} of{" "}
        {Math.ceil(totalCount / pageSize)}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    {/* <TableCell>Generated ID</TableCell> */}
                    <TableCell>First Name</TableCell>
                    <TableCell>Enrollment Time</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Package Plan</TableCell>
                    <TableCell>Expiration Date</TableCell>
                    <TableCell>Remaining Ticket Count</TableCell>
                    {/* {needInstructor && <TableCell>Need Instructor</TableCell>} */}
                    {/*handleShowInstructor(entity)*/}
                    <TableCell>Enrolled By</TableCell>
                    {/* <TableCell>Attended</TableCell> */}
                    {/* <TableCell>Absent</TableCell> */}
                    <TableCell className={classes.columnAction} align="right">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {entities.map((entity) => {
                    //const disableRow = (entity.status === "LATE_CANCELLED" || entity.status === "CANCELLED");

                    return (
                      <TableRow
                        hover
                        key={entity.id}
                        //className={clsx({[classes.disableRow]: disableRow})}
                      >
                        <TableCell>{entity.customerUser.username}</TableCell>
                        {/* <TableCell>{entity.customerUser.generatedId}</TableCell> */}
                        <TableCell>{entity.customerUser.firstName}</TableCell>
                        <TableCell>
                          {entity.enrollmentTime &&
                            moment(entity.enrollmentTime).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                        </TableCell>
                        <TableCell>
                          {entity.status &&
                            TRANSCRIPT_STATUS_VALUE_DISPLAY_MAPPING[
                              entity.status
                            ]}
                        </TableCell>
                        <TableCell>
                          {entity.purchasedPackagePlan &&
                            entity.purchasedPackagePlan.packagePlanName}
                        </TableCell>
                        <TableCell>
                          {entity.purchasedPackagePlan &&
                            moment(
                              entity.purchasedPackagePlan.expirationDate
                            ).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          {entity.purchasedPackagePlan &&
                            entity.purchasedPackagePlan.remainingTicketCount.toFixed(
                              2
                            )}{" "}
                          /{" "}
                          {entity.purchasedPackagePlan &&
                            entity.purchasedPackagePlan.originalTicketCount}
                        </TableCell>
                        {/* {needInstructor && (
                          <TableCell>
                            {entity.needInstructor ? "YES" : "No"}
                          </TableCell>
                        )} */}
                        <TableCell>
                          {entity.doneByStaffUser &&
                            `${entity.doneByStaffUser.firstName}`}
                          (
                          {entity.doneByType &&
                            TRANSCRIPT_DONE_BY_TYPE[entity.doneByType]}
                          )
                        </TableCell>
                        {/* <TableCell>
                          <Checkbox
                            // disabled={disableRow}
                            checked={Boolean(entity.attended)}
                            onClick={() =>
                              handleAttendChange(entity.attended, entity)
                            }
                          />
                        </TableCell> */}
                        {/* <TableCell>
                        <Checkbox 
                          disabled={disableRow} 
                          checked={Boolean(entity.absence)} 
                          onClick={handleAbsenceChange(entity.attended, entity.absence, entity)
                          } 
                        />
                      </TableCell> */}
                        {/* onChange={handleCheckBox} */}
                        <TableCell align="right">
                          <Link
                            to={`/customerUsers/${entity.customerUser.id}/purchasedPackagePlanUsages`}
                          >
                            <IconButton color="default" size="small">
                              <AccountBalanceWalletOutlinedIcon />
                            </IconButton>
                          </Link>
                          {/* <Tooltip title="Edit" placement="bottom">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onEdit(entity)}
                            disabled={
                              entity["deleting"] ||
                              entity["updating"] ||
                              entity.status === "CANCELLED" ||
                              entity.status === "LATE_CANCELLED"
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip> */}
                          <Tooltip title="Edit Package" placement="bottom">
                            <IconButton
                              color="default"
                              size="small"
                              onClick={() => onEditPackage(entity)}
                              disabled={
                                entity["deleting"] ||
                                entity["updating"] ||
                                entity.status === "CANCELLED" ||
                                entity.status === "LATE_CANCELLED"
                              }
                            >
                              <LoyaltyOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onDelete(entity)}
                            disabled={
                              entity["deleting"] ||
                              entity["updating"] ||
                              entity.status === "CANCELLED" ||
                              entity.status === "LATE_CANCELLED" ||
                              entity.status === "CANCELLED" ||
                              entity.status === "LATE_CANCELLED"
                            }
                          >
                            <CancelOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageNumberChange}
            onRowsPerPageChange={handlePageSizeChange}
            page={pageNumber}
            rowsPerPage={pageSize}
            rowsPerPageOptions={[5, 10, 25, 50]}
          />
        </CardActions>
        {loading && (
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        )}
      </Card>
    </div>
  );
};

export default ListingTable;

import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validationSchema,
} from "./AttributeFormDialog";
import { extractSelectOption } from "../../../utils/app.util";
import { GENDER_OPTIONS } from "../../../constants/app.constant";
import moment from "moment";

const EditForm = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/customerUsers/${entity.id}`)
      .then((response) => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);
        active && resetForm({ values: receivedValues });
      })
      .catch((error) => {
        onClose();

        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to client "${entity.username}"`,
              variant: "error",
            },
          },
          {
            message: `Unable to fetch client "${entity.username}"`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <AttributeFormDialog
      {...rest}
      title="Edit Client"
      open={open}
      onClose={onClose}
      loading={loading}
    />
  );
};

const FormikEditForm = (props) => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating client "${values.username}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/customerUsers/${values.id}`, createParams(values))
      .then(() => {
        notify(`Client "${values.username}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for client "${values.username}"`,
              variant: "warning",
            },
            403: {
              message: `Access denied to update clients`,
              variant: "error",
            },
          },
          {
            message: `Unable to update client "${values.username}"`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = (receivedValues) => {
  receivedValues.firstName || (receivedValues.firstName = "");
  receivedValues.lastName || (receivedValues.lastName = "");
  receivedValues.customerIdNo || (receivedValues.customerIdNo = "");
  receivedValues.ptNo || (receivedValues.ptNo = "");
  receivedValues.mobilePhone || (receivedValues.mobilePhone = "");
  receivedValues.registeredBranch || (receivedValues.registeredBranch = null);
  receivedValues.gender = extractSelectOption(
    GENDER_OPTIONS,
    "value",
    receivedValues.gender
  );
  receivedValues.birthday = receivedValues.birthday  
    ? moment().format("YYYY-MM-DD")
    : moment(receivedValues.birthday, "YYYY-MM-DD");
};

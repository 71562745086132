import React from "react";
import { Select } from "@material-ui/core";
import { useFormikContext } from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import _ from "lodash";

const FormikSelectField = (props) => {
  const {
    name,
    label,
    component: Component,
    options,
    onChange,
    getOptionLabel = () => {},
    getOption = () => {},
    ...rest
  } = props;

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    inputProps,
    setFieldValue,
  } = useFormikContext();

  const Field = Component || Select;

  return (
    <FormControl
      variant={rest.variant || "outlined"}
      margin={rest.margin || "dense"}
      required={props.required || false}
      fullWidth
    >
      <InputLabel
        id={(rest.id || name) + "-label"}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
      >
        {label}
      </InputLabel>
      <Field
        id={rest.id || name}
        labelId={(rest.id || name) + "-label"}
        fullWidth
        name={name}
        label={label}
        value={_.get(values, name) || ""}
        onChange={
          onChange ||
          ((event) => {
            setFieldValue(name, event.target.value);
          })
        }
        onBlur={handleBlur}
        disabled={rest.disabled || isSubmitting}
        error={Boolean(_.get(touched, name) && _.get(errors, name))}
        helpertext={_.get(touched, name) && _.get(errors, name)}
        inputProps={inputProps}
        {...rest}
      >
        <MenuItem value={null}>Please Select {label}</MenuItem>
        {options &&
          options.map((option) => (
            <MenuItem key={option} value={getOption(option) || option.value}>
              {getOptionLabel(option) || option.label}
            </MenuItem>
          ))}
      </Field>
      {Boolean(_.get(touched, name) && _.get(errors, name)) && (
        <FormHelperText error={true}>
          {_.get(touched, name) && _.get(errors, name)}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikSelectField;

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Frame from "components/Frame";
import PageHeader from "components/PageHeader";
import { Button, ButtonGroup, Grid, Typography } from "@material-ui/core";
import FilterForm from "./FilterForm";
import axios from "axios";
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
//import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  paper: {
    marginTop: 30,
  },
  toolbar: {
    marginTop: 10,
    marginBottom: 5,
  },
  table: {
    maxHeight: 550,
  },
  title: {
    minWidth: 150,
    color: "#ffffff",
    backgroundColor: "#034dcc",
  },
  header: {
    minWidth: 200,
    color: "#ffffff",
    backgroundColor: "#034dcc",
  },
  selectedCell: {
    backgroundColor: "rgba(52, 121, 117, 0.1)",
  },
  cell: {},
}));

const time = [
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
];

const ClassSchedulesPage = () => {
  const classes = useStyles();
  const [loadingTable, setLoadingTable] = useState(true);
  const [entity, setEntity] = useState([]);
  const [filters, setFilters] = useState({
    date: moment(new Date())
      .startOf("day")
      .format("YYYY-MM-DD"),
    offeringCategory: null,
    storeBranch: null,
    unPaged: true,
  });

  const [tableCol, setTableCol] = useState([]);
  const [tableRow, setTableRow] = useState([]);

  const handleCurrentDayChange = (value) => {
    setFilters({
      ...filters,
      date: moment(value).format("YYYY-MM-DD"),
    });
  };

  const handleValueChange = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const InstructorsCol = (data) => {
    var instructors = [];
    data &&
      data.length > 0 &&
      data.map((item) =>
        instructors.length > 0
          ? instructors.map(
              (i) =>
                i.id !== item.instructorStaffUser.id &&
                instructors.push({
                  id: item.instructorStaffUser.id,
                  title:
                    item.instructorStaffUser.firstName +
                    " " +
                    item.instructorStaffUser.lastName,
                })
            )
          : instructors.push({
              id: item.instructorStaffUser.id,
              title:
                item.instructorStaffUser.firstName +
                " " +
                item.instructorStaffUser.lastName,
            })
      );
    setTableCol(instructors);
  };

  const TimeSlotRow = (data) => {
    var timeSlots = [];
    data &&
      data.length > 0 &&
      data.map((item) =>
        timeSlots.push({
          resourceId: item.instructorStaffUser.id,
          title: item.customerUser.firstName + " " + item.customerUser.lastName,
          start: item.classSessionDate + "T" + item.startTime + "+00:00",
          end: item.classSessionDate + "T" + item.endTime + "+00:00",
        })
      );
    setTableRow(timeSlots);
  };

  const onDatePrev = () => {
    const nextDay = moment(filters.date)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    setFilters({
      ...filters,
      date: nextDay,
    });
  };

  const onDateToday = () => {
    setFilters({
      ...filters,
      date: moment()
        .startOf("day")
        .format("YYYY-MM-DD"),
    });
  };

  const onDateNext = () => {
    const nextDay = moment(filters.date)
      .add(1, "days")
      .format("YYYY-MM-DD");

    setFilters({
      ...filters,
      date: nextDay,
    });
  };

  useEffect(() => {
    setLoadingTable(true);
    axios
      .get("/api/classSessions", {
        params: {
          date: filters.date,
          offeringCategory:
            filters.offeringCategory && filters.offeringCategory.id,
          storeBranch: filters.storeBranch && filters.storeBranch.id,
          unPaged: true,
          //status: filters.status,
          //staffUsers: filters.staffUsers && filters.staffUsers.id,
          showInCalendar: true,
        },
      })
      .then((response) => {
        let data = response.data;
        //setEntity(data.content);
        //WorkInstructor(data.content);
        setLoadingTable(false);
        InstructorsCol(data.content);
        TimeSlotRow(data.content);
      });
  }, [filters]);

  return (
    <Frame className={classes.root}>
      <PageHeader section="Overview" title="Upcoming Class Schedules" />
      <FilterForm
        loading={loadingTable}
        onValueChange={handleValueChange}
        onCurrentDayChange={handleCurrentDayChange}
        values={filters}
      />
      <Grid
        alignItems="center"
        container
        justifyContent="space-between"
        spacing={3}
        className={classes.toolbar}
      >
        <Grid item>
          <ButtonGroup>
            <Button onClick={onDatePrev}>Prev</Button>
            <Button onClick={onDateToday}>Today</Button>
            <Button onClick={onDateNext}>Next</Button>
          </ButtonGroup>
        </Grid>
        <Grid item>
          <Typography variant="h3">
            {filters &&
              filters.date &&
              moment(filters.date).format("dddd, DD MMMM YYYY")}
          </Typography>
        </Grid>
      </Grid>
      {!loadingTable && (
        <FullCalendar
          schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          plugins={[resourceTimeGridPlugin]}
          initialView="resourceTimeGridDay"
          resources={tableCol}
          timeZone="UTC"
          events={tableRow}
          initialDate={filters.date}
          headerToolbar={false}
          dayHeaders={false}
          slotLabelInterval={"00:15"}
          slotLabelFormat={{
            hour: "numeric",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: "short",
          }}
          slotDuration={"00:15:00"}
          slotMinTime={"10:00"}
          slotMaxTime={"22:00"}
        />
      )}
    </Frame>
  );
};

export default ClassSchedulesPage;

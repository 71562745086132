import PeopleOutlinedIcon from "@material-ui/icons/PeopleOutlined";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import WidgetsOutlinedIcon from "@material-ui/icons/WidgetsOutlined";
import ShowChartOutlinedIcon from "@material-ui/icons/ShowChartOutlined";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import StoreOutlinedIcon from "@material-ui/icons/StoreOutlined";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import SystemUpdateAltOutlinedIcon from "@material-ui/icons/SystemUpdateAltOutlined";
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import WebIcon from "@material-ui/icons/Web";

import {
  CUSTOMER_USER_MANAGEMENT_PATH,
  MEMBERSHIP_TIER_MANAGEMENT_PATH,
  NOTIFICATION_MANAGEMENT_PATH,
  OFFERING_CATEGORY_MANAGEMENT_PATH,
  OFFERING_MANAGEMENT_PATH,
  OFFERING_SCHEDULE_SESSION_LIST_PATH,
  PACKAGE_PLAN_MANAGEMENT_PATH,
  UPCOMING_OFFERING_SCHEDULE_SESSION_LIST_PATH,
  REPORT_LIST_PATH,
  STAFF_USER_MANAGEMENT_PATH,
  SYSTEM_CONFIGURATION_PATH,
  CLOSED_BUSINESS_DAY_MANAGEMENT_PATH,
  MAIL_TEMPLATE_MANAGEMENT_PATH,
  BRANCH_MANAGEMENT_PATH,
  PROMOTION_MANAGEMENT_PATH,
  EMAIL_CLIENT_PATH,
  CRM_DATA_IMPORT_MANAGEMENT_PATH,
  FRONTEND__CONFIGURATION_PATH,
} from "../constants/route.constant";
import {
  ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
  ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
  ACCESS_CONTROL_ENTRY_MEMBERSHIP_TIER,
  ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
  ACCESS_CONTROL_ENTRY_NOTIFICATION,
  ACCESS_CONTROL_ENTRY_OFFERING,
  ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
  ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
  ACCESS_CONTROL_ENTRY_REPORT,
  ACCESS_CONTROL_ENTRY_STAFF_USER,
  ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
  ACCESS_CONTROL_ENTRY_FRONTEND_CONFIGURATION,
  ACCESS_CONTROL_LEVEL_READ,
  ACCESS_CONTROL_LEVEL_YES,
  ACCESS_CONTROL_ENTRY_STORE_BRANCH,
  ACCESS_CONTROL_ENTRY_PROMOTION,
  ACCESS_CONTROL_ENTRY_CRM_DATA_IMPORT,
} from "../constants/app.constant";

export default [
  {
    title: "Overview",
    items: [
      // {
      //   title: "Home",
      //   href: OFFERING_SCHEDULE_SESSION_LIST_PATH,
      //   icon: ScheduleOutlinedIcon,
      // },
      {
        title: "Home",
        href: OFFERING_SCHEDULE_SESSION_LIST_PATH,
        icon: ScheduleOutlinedIcon,
      },
    ],
  },
  {
    title: "Class Management",
    items: [
      {
        title: "Class Session",
        href: OFFERING_MANAGEMENT_PATH,
        icon: ClassOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
      {
        title: "Class Categories",
        href: OFFERING_CATEGORY_MANAGEMENT_PATH,
        icon: WidgetsOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_OFFERING_CATEGORY,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
    ],
  },
  {
    title: "Client Management",
    items: [
      {
        title: "Client Management",
        href: CUSTOMER_USER_MANAGEMENT_PATH,
        icon: PeopleOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_CUSTOMER_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
      {
        title: "Package Management",
        href: PACKAGE_PLAN_MANAGEMENT_PATH,
        icon: FolderOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_PACKAGE_PLAN,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
    ],
  },
  // {
  //   title: "Tier Management",
  //   items: [
  //     {
  //       title: "Membership Tier Management",
  //       href: MEMBERSHIP_TIER_MANAGEMENT_PATH,
  //       icon: SupervisedUserCircleOutlinedIcon,
  //       accessControls: [
  //         {
  //           entry: ACCESS_CONTROL_ENTRY_MEMBERSHIP_TIER,
  //           level: ACCESS_CONTROL_LEVEL_READ
  //         }
  //       ]
  //     },
  //   ]
  // },
  // {
  //   title: "Data Import",
  //   items: [
  //     {
  //       title: "CRM Data Import",
  //       href: CRM_DATA_IMPORT_MANAGEMENT_PATH,
  //       icon: SystemUpdateAltOutlinedIcon,
  //       accessControls: [
  //         {
  //           entry: ACCESS_CONTROL_ENTRY_CRM_DATA_IMPORT,
  //           level: ACCESS_CONTROL_LEVEL_READ,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    title: "Staff Management",
    items: [
      {
        title: "Staff Management",
        href: STAFF_USER_MANAGEMENT_PATH,
        icon: AccountBoxOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_STAFF_USER,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
    ],
  },
  {
    title: "Branch Management",
    items: [
      {
        title: "Branch Management",
        href: BRANCH_MANAGEMENT_PATH,
        icon: StoreOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_STORE_BRANCH,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
      /*{
        title: "Promotion Management",
        href: PROMOTION_MANAGEMENT_PATH,
        icon: LoyaltyOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_PROMOTION,
            level: ACCESS_CONTROL_LEVEL_READ
          }
        ]
      }*/
    ],
  },
  {
    title: "Administration",
    items: [
      {
        title: "Reports",
        href: REPORT_LIST_PATH,
        icon: ShowChartOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_REPORT,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ],
      },
      /*{
        title: "Notification",
        href: NOTIFICATION_MANAGEMENT_PATH,
        icon: NotificationsOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_NOTIFICATION,
            level: ACCESS_CONTROL_LEVEL_READ
          }
        ]
      },
      {
        title: "Mail Template",
        href: MAIL_TEMPLATE_MANAGEMENT_PATH,
        icon: MailOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
            level: ACCESS_CONTROL_LEVEL_READ,
          },
        ],
      },
      {
        title: "Mail Clients",
        href: EMAIL_CLIENT_PATH,
        icon: MarkunreadMailboxIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_MAIL_TEMPLATE,
            level: ACCESS_CONTROL_LEVEL_READ
          }
        ]
      },
      {
        title: "System Configuration",
        href: SYSTEM_CONFIGURATION_PATH,
        icon: SettingsOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_SYSTEM_CONFIGURATION,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ],
      },
      {
        title: "Closed Business Day",
        href: CLOSED_BUSINESS_DAY_MANAGEMENT_PATH,
        icon: EventBusyOutlinedIcon,
        accessControls: [
          {
            entry: ACCESS_CONTROL_ENTRY_CLOSED_BUSINESS_DAY,
            level: ACCESS_CONTROL_LEVEL_YES,
          },
        ],
      },*/
      // {
      //   title: "Frontend Configuration",
      //   href: FRONTEND__CONFIGURATION_PATH,
      //   icon: WebIcon,
      //   accessControls: [
      //     {
      //       entry: ACCESS_CONTROL_ENTRY_FRONTEND_CONFIGURATION,
      //       level: ACCESS_CONTROL_LEVEL_YES,
      //     },
      //   ],
      // },
    ],
  },
];

import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import { CUSTOMER_USER_MANAGEMENT_PATH } from "../../../constants/route.constant";
import IconSubTitle from "../../../components/IconSubTitle";
import { getCustomerUserName } from "../../../utils/app.util";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";

const ManagementPageHeader = (props) => {
  const { onCreate, parent } = props;

  const { isEditCustomerUserAllowed } = useAccessControl();

  return (
    <React.Fragment>
      <PageHeader
        section="Client Management"
        title="Package Plans / Membership"
        right={
          isEditCustomerUserAllowed() ? (
            <Button color="secondary" variant="contained" onClick={onCreate}>
              Add Package Plan / Membership
            </Button>
          ) : null
        }
        breadcrumbs={[
          {
            name: "Clients",
            link: CUSTOMER_USER_MANAGEMENT_PATH,
          },
        ]}
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: PersonOutlinedIcon,
                  description: getCustomerUserName(parent),
                },
              ]}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default ManagementPageHeader;

import React from "react";
import { Button } from "@material-ui/core";
import PageHeader from "../../../components/PageHeader";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import { OFFERING_MANAGEMENT_PATH } from "../../../constants/route.constant";
import ClassIcon from "@material-ui/icons/Class";
import IconSubTitle from "../../../components/IconSubTitle";

const ManagementPageHeader = (props) => {
  const { onCreate, parent } = props;

  const { isCreateOfferingScheduleAllowed } = useAccessControl();

  return (
    <PageHeader
      section="Class Management"
      title="Class Schedules"
      subTitle={
        parent && (
          <IconSubTitle
            config={[
              {
                icon: ClassIcon,
                description: parent.name,
              },
            ]}
          />
        )
      }
      right={
        isCreateOfferingScheduleAllowed() && (
          <Button color="secondary" variant="contained" onClick={onCreate}>
            Add Schedule
          </Button>
        )
      }
      breadcrumbs={[
        {
          name: "Classes",
          link: OFFERING_MANAGEMENT_PATH,
        },
      ]}
    />
  );
};

export default ManagementPageHeader;

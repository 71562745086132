import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: 240,
    maxHeight: 240,
  },
}));

const DisplayDialog = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const classes = useStyles();

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/offerings/${entity.id}`)
      .then((response) => {
        active && setDetailedEntity(response.data);
      })
      .catch((error) => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch class "${entity.name}"`,
                variant: "error",
              },
            },
            {
              message: `Unable to fetch class "${entity.name}"`,
              variant: "error",
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="Class Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{detailedEntity.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Class Category</TableCell>
            <TableCell>
              {detailedEntity.offeringCategory &&
                detailedEntity.offeringCategory.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>
              {detailedEntity.imageUrl && (
                <img
                  className={classes.image}
                  src={detailedEntity.imageUrl}
                  alt="Class"
                />
              )}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Membership Tier</TableCell>
            <TableCell>
              {detailedEntity &&
                detailedEntity.membershipTierFormList && (
                  <div>
                  <Table size="small">
                    <TableBody>
                      {detailedEntity.membershipTierFormList
                        .map(item => (
                          <TableRow hover key={item.idStr}>
                            <TableCell>{item.membershipTierName}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
                )}
            </TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>
              <div
                dangerouslySetInnerHTML={{
                  __html: detailedEntity.description,
                }}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;

import React from "react";
import ListingTable from "./components/ListingTable";
import RemoveConfirmationDialog from "./components/RemoveConfirmationDialog";
import CreateFormDialog from "./components/CreateFormDialog";
import EditFormDialog from "./components/EditFormDialog";
import ManagementPageHeader from "./components/ManagementPageHeader";
import SubEntityManagement from "../../components/SubEntityManagement";
import axios from "axios";

const CustomerUserPackagePlanPaymentManagement = (props) => {
  const {
    match: {
      params: { id, packageId },
    },
  } = props;

  return (
    <SubEntityManagement
      customerId={id}
      pageHeader={ManagementPageHeader}
      createFormDialog={CreateFormDialog}
      editFormDialog={EditFormDialog}
      removeConfirmationDialog={RemoveConfirmationDialog}
      listingTable={ListingTable}
      request={(params) => {
        return axios.get(
          `/api/purchasedPackagePlans/${packageId}/paymentRecords`,
          {
            params: {
              ...params,
            },
          }
        );
      }}
      parentApi={`/api/purchasedPackagePlans/${packageId}`}
    />
  );
};

export default CustomerUserPackagePlanPaymentManagement;

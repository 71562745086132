import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import AttributeFormDialog, {
  createParams,
  initialValues,
  validationSchema,
} from "./AttributeFormDialog";
import {
  GENDER_OPTIONS,
  STAFF_USER_ROLE_INSTRUCTOR,
} from "../../../constants/app.constant";
import { extractSelectOption } from "../../../utils/app.util";
import moment from "moment";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

const EditForm = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/staffUsers/${entity.id}`)
      .then((response) => {
        const receivedValues = response.data;

        normalizeReceivedValues(receivedValues);

        active && resetForm({ values: receivedValues });
      })
      .catch((error) => {
        onClose();
        //console.log(error);
        // notifyApiError(
        //   error.response.status,
        //   {
        //     403: {
        //       message: `Access denied to user "${entity.username}"`,
        //       variant: "error"
        //     }
        //   },
        //   {
        //     message: `Unable to fetch user "${entity.username}"`,
        //     variant: "error"
        //   }
        // );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <AttributeFormDialog
      {...rest}
      title="Edit User"
      open={open}
      onClose={onClose}
      loading={loading}
      edit={true}
    />
  );
};

const FormikEditForm = (props) => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating user "${values.username}"`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/staffUsers/${values.id}`, createParams(values))
      .then(() => {
        notify(`User "${values.username}" updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found for user "${values.username}"`,
              variant: "warning",
            },
            403: {
              message: `Access denied to update user`,
              variant: "error",
            },
          },
          {
            message: `Unable to update user "${values.username}"`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = (receivedValues) => {
  receivedValues.username || (receivedValues.username = "");
  receivedValues.password || (receivedValues.password = "");
  receivedValues.firstName || (receivedValues.firstName = "");
  receivedValues.lastName || (receivedValues.lastName = "");
  //receivedValues.middleName || (receivedValues.middleName = "");
  receivedValues.gender || (receivedValues.gender = null);
  receivedValues.birthday || (receivedValues.birthday = null);
  receivedValues.email || (receivedValues.email = "");
  //receivedValues.role || (receivedValues.role = ""); //STAFF_USER_ROLE_INSTRUCTOR.toString());
  receivedValues.role = receivedValues.role.id;
  receivedValues.mobilePhone || (receivedValues.mobilePhone = "");
  //receivedValues.homePhone || (receivedValues.homePhone = "");
  //receivedValues.workPhone || (receivedValues.workPhone = "");
  //receivedValues.street || (receivedValues.street = "");
  //receivedValues.city || (receivedValues.city = "");
  //receivedValues.stateOrProvince || (receivedValues.stateOrProvince = "");
  //receivedValues.country || (receivedValues.country = "");
  receivedValues.instructor || (receivedValues.instructor = false);
  // (receivedValues.storeBranches && receivedValues.storeBranches[0].id) ||
  //   (receivedValues.storeBranches = null);
  receivedValues.emergencyContact || (receivedValues.emergencyContact = "");
  receivedValues.emergencyRelationship ||
    (receivedValues.emergencyRelationship = "");
  let selectedBranches = [];
  receivedValues.storeBranches &&
    receivedValues.storeBranches.map((item) => {
      return selectedBranches.push(item.id.toString());
    });

  receivedValues.storeBranchesId = selectedBranches;
  receivedValues.gender = extractSelectOption(
    GENDER_OPTIONS,
    "value",
    receivedValues.gender
  );

  receivedValues.birthday &&
    (receivedValues.birthday = moment(receivedValues.birthday, "YYYY-MM-DD"));
  receivedValues.role = receivedValues.role.toString();
};

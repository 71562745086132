import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  GENDER_CODE_NAME_MAPPING,
  STAFF_USER_ROLE_ID_NAME_MAPPING,
} from "../../../constants/app.constant";
import moment from "moment";
import LoadableDialog from "../../../components/dialogs/LoadableDialog";
import axios from "axios";
import { useApiNotification } from "../../../hooks/notification.hook";

const DisplayDialog = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const [detailedEntity, setDetailedEntity] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }

    let active = true;

    setLoading(true);

    axios
      .get(`/api/staffUsers/${entity.id}`)
      .then((response) => {
        active && setDetailedEntity(response.data);
      })
      .catch((error) => {
        active && onClose();

        active &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to fetch user "${entity.username}"`,
                variant: "error",
              },
            },
            {
              message: `Unable to fetch user "${entity.username}"`,
              variant: "error",
            }
          );
      })
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <LoadableDialog
      {...rest}
      open={open}
      onClose={onClose}
      loading={loading}
      title="User Information"
    >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Username</TableCell>
            <TableCell>{detailedEntity.username}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>{detailedEntity.firstName}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last Name</TableCell>
            <TableCell>{detailedEntity.lastName}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Middle Name</TableCell>
            <TableCell>{detailedEntity.middleName}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Gender</TableCell>
            <TableCell>
              {detailedEntity.gender &&
                GENDER_CODE_NAME_MAPPING[detailedEntity.gender]}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Birthday</TableCell>
            <TableCell>
              {detailedEntity.birthday &&
                moment(detailedEntity.birthday).format("YYYY-MM-DD")}
            </TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>{detailedEntity.email}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Role</TableCell>
            <TableCell>
              {detailedEntity.role && detailedEntity.role.name
              //STAFF_USER_ROLE_ID_NAME_MAPPING[detailedEntity.role]
              }
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Mobile Phone</TableCell>
            <TableCell>{detailedEntity.mobilePhone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Emergency Contact</TableCell>
            <TableCell>{detailedEntity.emergencyContact}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Emergency Relationship</TableCell>
            <TableCell>{detailedEntity.emergencyRelationship}</TableCell>
          </TableRow>
          {/* <TableRow>
            <TableCell>Home Phone</TableCell>
            <TableCell>{detailedEntity.homePhone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Work Phone</TableCell>
            <TableCell>{detailedEntity.workPhone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Street</TableCell>
            <TableCell>{detailedEntity.street}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>City</TableCell>
            <TableCell>{detailedEntity.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>State or Province</TableCell>
            <TableCell>{detailedEntity.stateOrProvince}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Country</TableCell>
            <TableCell>{detailedEntity.country}</TableCell>
          </TableRow> */}
          <TableRow>
            <TableCell>Instructor?</TableCell>
            <TableCell>{detailedEntity.instructor ? "Yes" : "No"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Work Location</TableCell>
            <TableCell>
              {detailedEntity.workLocation && detailedEntity.workLocation.name}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </LoadableDialog>
  );
};

export default DisplayDialog;

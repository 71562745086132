import React, { useCallback } from "react";
import axios from "axios";
import FormikLoadableSelect from "./FormikLoadableSelect";

const FormikOfferingCategorySelect = (props) => {
  const request = useCallback(() => {
    return axios.get("/api/offeringCategories/variants/references");
    //return axios.get("/api/offeringCategories?page=0&size=-1");
  }, []);

  return (
    <FormikLoadableSelect
      getOptionLabel={(option) => (option && option.name ? option.name : "")}
      {...props}
      request={request}
    />
  );
};

export default FormikOfferingCategorySelect;

import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import * as Yup from "yup";
import FormikTextField from "components/inputs/FormikTextField";
import { coreCreateParams } from "components/CoreCreateParams";
import FormikReferenceField from "components/inputs/FormikReferenceField";
import FormikMultiReferenceField from "components/inputs/FormikMultiReferenceField";
import FormikSelectField from "components/inputs/FormikSelectField";
import { PACKAGE_PLAN_PAYMENT_METHOD } from "constants/app.constant";

const useStyles = makeStyles((theme) => ({
  sectionLabel: {
    ...theme.typography.h5,
  },
}));

const EditForm = (props) => {
  const { open, onClose, entity, ...rest } = props;

  const { resetForm, values } = useFormikContext();

  const [loading, setLoading] = React.useState(false);

  const notifyApiError = useApiNotification();

  React.useLayoutEffect(() => {
    if (!entity) {
      return;
    }
    resetForm({ values: initialValues });
    let active = true;

    setLoading(true);

    axios
      .get(`/api/paymentRecords/${entity.id}`)
      .then((response) => {
        const receivedValues = response.data;
        normalizeReceivedValues(receivedValues);
        active && resetForm({ values: receivedValues });
      })
      .catch((error) => {})
      .finally(() => {
        active && setLoading(false);
      });

    return () => {
      active = false;
    };
  }, [entity, resetForm, notifyApiError, onClose]);

  if (!entity) {
    return null;
  }

  return (
    <FormikFormDialog
      {...rest}
      title={"Edit Package Plan"}
      open={open}
      onClose={onClose}
      loading={loading}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              required
              disablePast
              name="dueDate"
              label="Due Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker name="paymentDate" label="Payment Date" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSelectField
              name="paymentMethod"
              label="Payment Method"
              options={PACKAGE_PLAN_PAYMENT_METHOD}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikTextField
              required
              fullWidth
              name="amount"
              label="Amount"
              margin="dense"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField
              fullWidth
              name="remarks"
              label="Remarks"
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
};

const validationSchema = Yup.object().shape({
  paymentDate: Yup.date()
    .nullable(true)
    .required("Required"),
  dueDate: Yup.date()
    .nullable(true)
    .required("Required"),
  amount: Yup.string()
    .required("Required")
    .nullable(),
  remarks: Yup.string().nullable(),
});

const validate = (values) => {
  const errors = {};

  // if (values.effectiveDate && values.expirationDate) {
  //   if (values.expirationDate.isBefore(values.effectiveDate)) {
  //     errors.endDate = "Cannot be earlier than effective date";
  //   }
  // }

  return errors;
};

const initialValues = {
  paymentDate: null,
  dueDate: null,
  paymentMethod: null,
  amount: "",
  remarks: "",
};

const createParams = (values) => {
  const editableField = [
    "paymentDate",
    "dueDate",
    "paymentMethod",
    "amount",
    "remarks",
  ];

  values.paymentDate =
    values.paymentDate && moment(values.paymentDate).format("YYYY-MM-DD");
  values.dueDate =
    values.dueDate && moment(values.dueDate).format("YYYY-MM-DD");

  const params = coreCreateParams(editableField, values);
  return params;
};

const FormikEditForm = (props) => {
  const {
    entity,
    onOpen,
    onClose,
    onSuccess,
    onBefore,
    onAfter,
    ...rest
  } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Updating payment record`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .put(`/api/paymentRecords/${values.id}`, createParams(values))
      .then(() => {
        notify(`Payment record updated`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning",
            },
            403: {
              message: `Access denied to update payment record`,
              variant: "error",
            },
          },
          {
            message: `Unable to update payment record`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        onAfter(entity);

        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <EditForm {...rest} entity={entity} onClose={onClose} />
    </Formik>
  );
};

export default FormikEditForm;

const normalizeReceivedValues = (receivedValues) => {};

import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";

const RemoveConfirmationDialog = (props) => {
  const { open, onClose, onBefore, onAfter, onSuccess, entity } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleRemove = () => {
    if (!entity) {
      return;
    }

    onClose();

    onBefore(entity.id);

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing package plan "${entity.name}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .delete(`/api/packagePlans/${entity.id}`)
      .then(() => {
        notify(`Package plan "${entity.name}" removed`, "success");

        onSuccess();
      })
      .catch((error) => {
        error.response &&
          error.response.status &&
          notifyApiError(
            error.response.status,
            {
              403: {
                message: `Access denied to remove package plan "${entity.name}"`,
                variant: "error",
              },
            },
            {
              message: `Unable to remove package plan "${entity.name}"`,
              variant: "error",
            }
          );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };

  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Package Plan?"
      message={
        <React.Fragment>
          Package plan <b>{entity.name}</b> is going to be removed. You will not
          be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
};

export default RemoveConfirmationDialog;

import React from "react";
import { Card, makeStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import clsx from "clsx";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import ExcelIcon from "../../../icons/ExcelIcon";
import Typography from "@material-ui/core/Typography";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    justifyContent: "flex-end",
  },
  icon: {
    fill: green["800"],
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
}));

export default function ReportCard(props) {
  const {
    className,
    title,
    onStart,
    children,
    contentClassName,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={contentClassName}>
        <ExcelIcon className={classes.icon} />
        <Typography variant="h5" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {children}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button size="small" color="primary" onClick={onStart}>
          Download
        </Button>
      </CardActions>
    </Card>
  );
}

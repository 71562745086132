import React, { useCallback } from "react";
import LoadableSelect from "./LoadableSelect";
import { useFormikContext } from "formik";

const FormikLoadableSelect = (props) => {
  const { name, ...rest } = props;

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
    isSubmitting,
  } = useFormikContext();

  const onLoadError = useCallback(
    (error) => {
      setFieldTouched(name, true, false);
      setFieldError(name, error.response.statusText);
    },
    [name, setFieldError, setFieldTouched]
  );

  return (
    <LoadableSelect
      value={values[name]}
      onChange={(event, value) => setFieldValue(name, value)}
      name={name}
      onBlur={handleBlur}
      onLoadError={onLoadError}
      disabled={isSubmitting}
      error={Boolean(touched[name] && errors[name])}
      helperText={touched[name] && errors[name]}
      {...rest}
    />
  );
};

export default FormikLoadableSelect;

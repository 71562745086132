import React from "react";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import axios from "axios";
import getAllFieldErrors from "../../../utils/api.util";
import { Formik } from "formik";
import moment from "moment";
import fileDownload from "js-file-download";
import EmptyEnabledFormikFormDialog from "../../../components/dialogs/EmptyEnabledFormikFormDialog";

const MonthlyNmuReportConfigFormDialog = (props) => {
  const { open, onClose, ...rest } = props;

  return (
    <EmptyEnabledFormikFormDialog
      {...rest}
      title="Monthly Nmu Report"
      open={open}
      onClose={onClose}
      loading={false}
    >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="effectiveDateFrom"
              label="Effective Start Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="effectiveDateTo"
              label="Effective End Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="expiryDateFrom"
              label="Expiry Start Date"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikKeyboardDatePicker
              name="expiryDateTo"
              label="Expiry End Date"
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </EmptyEnabledFormikFormDialog>
  );
};

const FormikMonthlyNmuReportConfigFormDialog = (props) => {
  const { onOpen, onClose, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Running monthly nmu report`);
    }, PROGRESS_TIME_LIMIT);

    return axios
      .get("/api/reports/monthlyNmu", {
        params: {
          effectiveDateFrom: values.effectiveDateFrom
            ? moment(values.effectiveDateFrom).format("YYYY-MM-DD")
            : undefined,
          effectiveDateTo: values.effectiveDateTo
            ? moment(values.effectiveDateTo).format("YYYY-MM-DD")
            : undefined,
          expiryDateFrom: values.expiryDateFrom
            ? moment(values.expiryDateFrom).format("YYYY-MM-DD")
            : undefined,
          expiryDateTo: values.expiryDateTo
            ? moment(values.expiryDateTo).format("YYYY-MM-DD")
            : undefined,
        },
        responseType: "blob",
      })
      .then((response) => {
        notify(`Monthly Nmu report completed`, "success");

        formikActions.resetForm();

        fileDownload(response.data, "monthly-nmu-report.xlsx");
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning",
            },
            403: {
              message: `Access denied to run report`,
              variant: "error",
            },
          },
          {
            message: `Unable to run report`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      <MonthlyNmuReportConfigFormDialog {...rest} onClose={onClose} />
    </Formik>
  );
};

export default FormikMonthlyNmuReportConfigFormDialog;

const initialValues = {
  effectiveDateFrom: null,
  effectiveDateTo: null,
  expiryDateFrom: null,
  expiryDateTo: null,
};

const validate = (values) => {
  const errors = {};

  if (values.effectiveDateFrom && values.effectiveDateTo) {
    if (values.effectiveDateTo.isBefore(values.effectiveDateFrom)) {
      errors.effectiveDateTo = "Cannot be earlier than start date";
    }
  }

  if (values.expiryDateFrom && values.expiryDateTo) {
    if (values.expiryDateTo.isBefore(values.expiryDateFrom)) {
      errors.expiryDateTo = "Cannot be earlier than start date";
    }
  }

  return errors;
};

import React from "react";
import { makeStyles } from "@material-ui/core";
import * as Yup from "yup";
import Grid from "@material-ui/core/Grid";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { BOOLEAN_OPTIONS } from "../../../constants/app.constant";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import FormikTextField from "../../../components/inputs/FormikTextField";
import PasswordField from "../../../components/inputs/PasswordField";
import FormikAutocomplete from "../../../components/inputs/FormikAutocomplete";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikSwitch from "../../../components/inputs/FormikSwitch";
import FormikImageDropzone from "../../../components/inputs/FormikImageDropzone";
import FormikStoreBranchSelect from "../../../components/inputs/FormikStoreBranchSelect";

const useStyles = makeStyles(theme => ({
  sectionLabel: {
    ...theme.typography.h5
  }
}));

export default function AttributeFormDialog(props) {
  const { title, open, loading, onClose, ...rest } = props;

  const classes = useStyles();

  return (
    <FormikFormDialog {...rest} title={title} open={open} onClose={onClose} loading={loading}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid container spacing={1}>
          <Grid item md={12} xs={12}>
            <FormikTextField required fullWidth label="Membership Tier Name" name="membershipTierName" variant="outlined" margin="dense" />
          </Grid>
          <Grid item md={12} xs={12}>
            <FormikTextField required fullWidth label="Description" name="description" variant="outlined" margin="dense" />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="isDeleted"
              label="Activate"
              description="Indicate if this tier is activate"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <FormikSwitch
              name="isEligibleForMonthlyStatement"
              label="Monthly Statement"
              description="Indicate if this tier is eligible for monthly statement"
              FormLabelProps={{
                className: classes.sectionLabel
              }}
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </FormikFormDialog>
  );
}

export const validationSchema = Yup.object().shape({
  membershipTierName: Yup.string()
    .min(2, "At least 2 characters long")
    .max(50, "No more than 50 characters long")
    .matches(/^[a-zA-Z0-9_.]*$/, "Only alphanumeric characters, dots and underscores allowed")
    .required("Required"),
  description: Yup.string()
    .max(100, "No more than 100 characters long")
    .required("Required")
});

export const initialValues = {
  id: null,
  membershipTierName: "",
  description: "",
  isDeleted: true,
  isEligibleForMonthlyStatement: true,
  isDeletedRe: "",
};

export const createParams = values => {
  const params = new URLSearchParams();

  if(values.isDeleted==true){
    values.isDeletedRe=false;
  }else{
    values.isDeletedRe=true;
  }
  values.membershipTierName && params.append("membershipTierName", values.membershipTierName);
  values.description && params.append("description", values.description);
  params.append("isDeleted", values.isDeletedRe);
  params.append("isEligibleForMonthlyStatement", values.isEligibleForMonthlyStatement);

  return params;
};

import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { useFormikContext } from "formik";
import { makeStyles, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { alpha } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  group: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

const StyledToggleButton = withStyles((theme) => ({
  root: {
    width: 48,
    "&:not(:first-child)": {
      marginLeft: 0,
    },
    "&:first-child": {
      marginLeft: 0,
    },
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    },
    "&$selected": {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.12),
      "&:hover": {
        backgroundColor: alpha(theme.palette.secondary.main, 0.15),
      },
      "& + &": {
        borderLeft: "1px solid transparent",
        marginLeft: 0,
      },
    },
  },
  selected: {},
}))(ToggleButton);

const FormikWeekToggleButtonGroup = (props) => {
  const { name, ...rest } = props;

  const classes = useStyles();

  const {
    values,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
  } = useFormikContext();

  return (
    <ToggleButtonGroup
      className={classes.group}
      size="small"
      {...rest}
      value={values[name]}
      onChange={(event, value) => {
        setFieldTouched(name, true, false);
        setFieldValue(name, value);
      }}
    >
      <StyledToggleButton value={1} disabled={isSubmitting}>
        <Typography variant="button">MON</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={2} disabled={isSubmitting}>
        <Typography variant="button">TUE</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={3} disabled={isSubmitting}>
        <Typography variant="button">WED</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={4} disabled={isSubmitting}>
        <Typography variant="button">THU</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={5} disabled={isSubmitting}>
        <Typography variant="button">FRI</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={6} disabled={isSubmitting}>
        <Typography variant="button">SAT</Typography>
      </StyledToggleButton>
      <StyledToggleButton value={7} disabled={isSubmitting}>
        <Typography variant="button">SUN</Typography>
      </StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default FormikWeekToggleButtonGroup;

import React, { useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { OFFERING_MANAGEMENT_PATH } from "../../../constants/route.constant";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import IconSubTitle from "../../../components/IconSubTitle";
import ClassOutlinedIcon from "@material-ui/icons/ClassOutlined";
import FaceOutlinedIcon from "@material-ui/icons/FaceOutlined";
import { retrieveInstructorName } from "../../../utils/app.util";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import moment from "moment";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import { Button } from "@material-ui/core";
import { QrCodeDialog } from "layouts/SideLayout/components/QrCodeDialog";
import { set } from "lodash";

const ManagementPageHeader = (props) => {
  const { onCreate, parent } = props;
  const [open, setOpen] = useState(false);

  const {
    day,
    startTime,
    endTime,
    capacity,
    onlineCapacity,
    waitListCapacity,
    offering,
    offeringSchedule,
    instructor,
    storeBranch,
    bookedCount,
    cancelledCount,
    waitListedCount,
    pendingConfirmationCount,
  } = parent || {};

  const { isEnrollClientAllowed } = useAccessControl();
  return (
    <React.Fragment>
      <PageHeader
        section="Class Management"
        title="Enrolled Clients"
        breadcrumbs={
          parent && [
            {
              name: "Classes",
              link: OFFERING_MANAGEMENT_PATH,
            },
            {
              name: "Schedules",
              link: `/offerings/${offering && offering.id}/offeringSchedules`,
            },
            {
              name: "Sessions",
              link: `/offeringSchedules/${offeringSchedule &&
                offeringSchedule.id}/offeringScheduleSessions`,
            },
          ]
        }
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: ClassOutlinedIcon,
                  description: offering && offering.name,
                },
                {
                  icon: FaceOutlinedIcon,
                  description: retrieveInstructorName(instructor),
                },
                {
                  icon: EventOutlinedIcon,
                  description: moment(day).format("YYYY-MM-DD"),
                },
                {
                  icon: ScheduleOutlinedIcon,
                  description: `${moment(startTime, "HH:mm").format(
                    "HH:mm"
                  )} - ${moment(endTime, "HH:mm").format("HH:mm")}`,
                },
                {
                  icon: GroupOutlinedIcon,
                  description: `${bookedCount}/${onlineCapacity}`,
                },
                {
                  icon: HourglassEmptyOutlinedIcon,
                  description: `${waitListedCount +
                    pendingConfirmationCount}/${waitListCapacity}`,
                },
                {
                  icon: LocationOnOutlinedIcon,
                  description: storeBranch && storeBranch.name,
                },
              ]}
            />
          )
        }
        right={
          isEnrollClientAllowed && (
            <div>
              {/* <Button
                color="secondary"
                variant="contained"
                onClick={() => setOpen(true)}
                style={{ marginRight: 15 }}
              >
                Take Attendance
              </Button> */}
              <Button color="secondary" variant="contained" onClick={onCreate}>
                Enroll Client
              </Button>
            </div>
          )
        }
      />
      <QrCodeDialog
        entity={{ id: parent?.id, class: offering?.name }}
        open={open}
        onClose={() => setOpen(false)}
      />
    </React.Fragment>
  );
};

export default ManagementPageHeader;

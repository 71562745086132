import PerfectScrollbar from "react-perfect-scrollbar";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import React from "react";
import moment from "moment";
import numeral from "numeral";
import {
  retrieveInstructorName,
  startTimeEndTimeComparator,
} from "../../../utils/app.util";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { useAccessControl } from "../../../hooks/accessControl.hook";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteSweepOutlinedIcon from "@material-ui/icons/DeleteSweepOutlined";
import AccessTimeOutlinedIcon from "@material-ui/icons/AccessTimeOutlined";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  table: {
    whiteSpace: "nowrap",
    minWidth: 600,
  },
  inner: {
    overflowX: "auto",
  },
  date: {
    width: 240,
  },
  duration: {
    width: 120,
  },
  signedIn: {
    width: 120,
  },
  instructor: {
    width: 200,
  },
}));

const ScheduleSessionTable = (props) => {
  const {
    day,
    sessions,
    onDelete,
    onEdit,
    onEditSessionTime,
    onDeleteBatch,
  } = props;

  const classes = useStyles();

  const dayMoment = moment(day);

  sessions.sort(startTimeEndTimeComparator);

  const { isDeleteOfferingScheduleAllowed } = useAccessControl();

  return (
    <Paper className={classes.paper}>
      <PerfectScrollbar>
        <div className={classes.inner}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.date}>
                  {dayMoment.format("ddd, DD MMMM YYYY")}
                </TableCell>
                <TableCell className={classes.signedIn}>
                  Enrollment Figure
                </TableCell>
                <TableCell>Class Name</TableCell>
                <TableCell className={classes.instructor}>Instructor</TableCell>
                <TableCell className={classes.instructor}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map((session) => {
                const {
                  id,
                  startTime,
                  endTime,
                  offering,
                  offeringSchedule,
                  instructor,
                  onlineCapacity,
                  bookedCount,
                  cancelledCount,
                  waitListedCount,
                  pendingConfirmationCount,
                } = session;

                const startTimeMoment = moment(startTime, "HH:mm");
                const endTimeMoment = moment(endTime, "HH:mm");

                const startTimeText = startTimeMoment.format("HH:mm");
                const endTimeText = endTimeMoment.format("HH:mm");

                const instructorName = retrieveInstructorName(instructor);

                const duration = numeral(
                  endTimeMoment.diff(startTimeMoment, "hours", true)
                ).format("0.0");

                return (
                  <TableRow
                    key={`${startTimeText} ${endTimeText} ${instructorName}`}
                  >
                    <TableCell>{`${startTimeText} - ${endTimeText}`}</TableCell>
                    <TableCell>
                      <Link to={`/offeringScheduleSessions/${id}/transcripts`}>
                        {bookedCount}/{onlineCapacity} (
                        {waitListedCount + pendingConfirmationCount})
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: `/offeringSchedules/${offeringSchedule.id}/offeringScheduleSessions`,
                          state: {
                            specificDay: day,
                          },
                        }}
                      >
                        {offering.name}
                      </Link>
                    </TableCell>
                    <TableCell>{retrieveInstructorName(instructor)}</TableCell>
                    <TableCell>
                      {
                        /*isEditOfferingScheduleAllowed() &&*/ <Tooltip
                          title="Edit Session"
                          placement="bottom"
                        >
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onEdit(session)}
                            disabled={
                              session["deleting"] || session["updating"]
                            }
                          >
                            <EditOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      {
                        /*isEditOfferingScheduleAllowed() &&*/ <Tooltip
                          title="Edit Class"
                          placement="bottom"
                        >
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onEditSessionTime(session)}
                            disabled={
                              session["deleting"] || session["updating"]
                            }
                          >
                            <AccessTimeOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      }
                      {isDeleteOfferingScheduleAllowed() && (
                        <Tooltip title="Delete Session" placement="bottom">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onDelete(session)}
                            disabled={
                              session["deleting"] || session["updating"]
                            }
                          >
                            <DeleteOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {isDeleteOfferingScheduleAllowed() && (
                        <Tooltip title="Delete Class" placement="bottom">
                          <IconButton
                            color="default"
                            size="small"
                            onClick={() => onDeleteBatch(session)}
                            disabled={
                              session["deleting"] || session["updating"]
                            }
                          >
                            <DeleteSweepOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </PerfectScrollbar>
    </Paper>
  );
};

export default ScheduleSessionTable;

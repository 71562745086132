import React from "react";
import PageHeader from "../../../components/PageHeader";
import { CUSTOMER_USER_MANAGEMENT_PATH } from "../../../constants/route.constant";
import IconSubTitle from "../../../components/IconSubTitle";
import { getCustomerUserName } from "../../../utils/app.util";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";

const ManagementPageHeader = (props) => {
  const { parent } = props;

  return (
    <React.Fragment>
      <PageHeader
        section="Client Management"
        title="Package Plan / Membership Balances"
        breadcrumbs={[
          {
            name: "Clients",
            link: CUSTOMER_USER_MANAGEMENT_PATH,
          },
        ]}
        subTitle={
          parent && (
            <IconSubTitle
              config={[
                {
                  icon: PersonOutlinedIcon,
                  description: getCustomerUserName(parent),
                },
              ]}
            />
          )
        }
      />
    </React.Fragment>
  );
};

export default ManagementPageHeader;

import React from "react";
import ConfirmationDialog from "../../../components/dialogs/ConfirmationDialog";
import {
  useApiNotification,
  useNotification
} from "../../../hooks/notification.hook";
import {PROGRESS_TIME_LIMIT} from "../../../config/app.config";
import axios from "axios";
import moment from "moment";

const RemoveConfirmationDialog = props => {
  const {open, onClose, onBefore, onAfter, onSuccess, entity} = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();
  
  const handleRemove = () => {
    if (!entity) {
      return;
    }
    
    onClose();

    onBefore(entity.id);
   
    const displayProgressTimeoutKey = setTimeout(() => {
      notify(`Removing session "${moment(entity.scheduledDay).format("YYYY-MM-DD")}"`);
    }, PROGRESS_TIME_LIMIT);

    axios
      .put(`/api/offeringScheduleSessions/${entity.id}`, createParamsCancel(entity, entity))
      .then(() => {
        notify(`Session "${moment(entity.scheduledDay).format("YYYY-MM-DD")}" removed`, "success");

        onSuccess();
      })
      .catch(error => {
        notifyApiError(
          error.response.status,
          {
            403: {
              message: `Access denied to remove session "${moment(entity.scheduledDay).format("YYYY-MM-DD")}"`,
              variant: "error"
            }
          },
          {
            message: `Unable to remove session "${moment(entity.scheduledDay).format("YYYY-MM-DD")}"`,
            variant: "error"
          }
        );
      })
      .finally(() => {
        onAfter(entity.id);

        clearTimeout(displayProgressTimeoutKey);
      });
  };
  if (!entity) {
    return null;
  }

  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      title="Remove Session?"
      message={
        <React.Fragment>
          Session <b>{moment(entity.scheduledDay).format("YYYY-MM-DD")}</b> is going to be deleted. You will not
          be able to recover the data once removed.
        </React.Fragment>
      }
      onConfirm={handleRemove}
    />
  );
}

export default RemoveConfirmationDialog;

  export const createParamsCancel = (entity) => {
    const params = new URLSearchParams();

    entity.instructor && params.append("instructor", entity.instructor.id);
    entity.day && params.append("day", entity.day);
    entity.startTime && params.append("startTime", entity.startTime.substring(0,5));
    entity.endTime && params.append("endTime", entity.endTime.substring(0,5));
  
    params.append("status", 'CANCELLED');
  
    return params;
  };
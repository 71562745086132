import React from "react";
import { Formik, useFormikContext } from "formik";
import axios from "axios";
import {
  useApiNotification,
  useNotification,
} from "../../../hooks/notification.hook";
import { PROGRESS_TIME_LIMIT } from "../../../config/app.config";
import getAllFieldErrors from "../../../utils/api.util";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Grid from "@material-ui/core/Grid";
import FormikPackagePlanSelect from "../../../components/inputs/FormikPackagePlanSelect";
import moment from "moment";
import FormikKeyboardDatePicker from "../../../components/inputs/FormikKeyboardDatePicker";
import FormikFormDialog from "../../../components/dialogs/FormikFormDialog";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import FormikRadioGroup from "../../../components/inputs/FormikRadioGroup";
import { PACKAGE_PLAN_TYPE_OPTIONS } from "constants/app.constant";
import FormikTextField from "components/inputs/FormikTextField";
import FormikSwitch from "components/inputs/FormikSwitch";
import { coreCreateParams } from "components/CoreCreateParams";
import { useParams } from "react-router";
import FormikReferenceField from "components/inputs/FormikReferenceField";
import FormikMultiReferenceField from "components/inputs/FormikMultiReferenceField";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
}));

const CreateForm = (props) => {
  const { open, onClose, onEnroll, parent, ...rest } = props;

  const { setFieldValue, values, resetForm, errors } = useFormikContext();
  const { id } = useParams();
  values.customerUser = id;

  const handleTypeChange = (event) => {
    resetForm({ values: initialValues });
    setFieldValue("type", event.target.value);
  };

  return (
    <div>
      <FormikFormDialog
        {...rest}
        title={"Add Package Plan / Membership"}
        open={open}
        onClose={onClose}
        loading={false}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <FormikRadioGroup
                required
                name="type"
                label="Type"
                options={PACKAGE_PLAN_TYPE_OPTIONS}
                onChange={handleTypeChange}
              />
            </Grid>
            {values.type === "PACKAGE_PLAN" && (
              <>
                <Grid item md={6} xs={12}>
                  <FormikPackagePlanSelect
                    required
                    fullWidth
                    name="packagePlan"
                    label="Package Plan"
                    margin="dense"
                    variant="outlined"
                    onChange={(event, value) => {
                      setFieldValue("packagePlan", value, false);
                    }}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormikTextField
                    required
                    fullWidth
                    name="quantity"
                    label="Quantity"
                    margin="dense"
                    variant="outlined"
                    type="number"
                  />
                </Grid>
              </>
            )}
            <Grid item md={6} xs={12}>
              <FormikKeyboardDatePicker
                required
                name="effectiveDate"
                label="Effective Date"
              />
            </Grid>
            {values.type === "PACKAGE_PLAN" && (
              <Grid item md={6} xs={12}>
                <FormikKeyboardDatePicker
                  required
                  disablePast
                  name="expiryDate"
                  label="Expiration Date"
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <FormikMultiReferenceField
                fullWidth
                name="salesStaffUsers"
                label="Sales Staff *"
                margin="dense"
                variant="outlined"
                apiUrl={"/api/staffUsers/variants/references"}
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                getOptionSelected={(option, value) => option.id == value.id}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              {/* <FormikStaffUserSelect
                required
                fullWidth
                name="inChargeStaffUser"
                label="Incharge Staff"
                margin="dense"
                variant="outlined"
              /> */}
              <FormikReferenceField
                required
                label="Incharge Staff"
                name="inChargeStaffUser"
                apiUrl={"/api/staffUsers/variants/references"}
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                getOptionValue={(option) => option?.id}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikTextField
                required
                fullWidth
                name="deposit"
                label="Deposit"
                margin="dense"
                variant="outlined"
                type="number"
              />
            </Grid>
            {values.type === "MEMBERSHIP" && (
              <Grid item md={6} xs={12}>
                <FormikTextField
                  required
                  fullWidth
                  name="totalAmount"
                  label="Total Amount"
                  margin="dense"
                  variant="outlined"
                  type="number"
                />
              </Grid>
            )}
            <Grid item md={6} xs={12}>
              <FormikTextField
                fullWidth
                name="remarks"
                label="Remarks"
                margin="dense"
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSwitch
                name="active"
                label="Toggle Status"
                description="Indicate if this package plan / membership is still used"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikSwitch
                name="fsDone"
                label="FS"
                description="Indicate if this FS is still used"
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </FormikFormDialog>
    </div>
  );
};

const validationSchema = Yup.object().shape({
  packagePlan: Yup.object()
    .nullable(true)
    .when("type", {
      is: "PACKAGE_PLAN",
      then: Yup.string().required("Required"),
    }),
  type: Yup.object().nullable(true),
  quantity: Yup.string().when("type", {
    is: "PACKAGE_PLAN",
    then: Yup.string().required("Required"),
  }),
  effectiveDate: Yup.date()
    .nullable(true)
    .required("Required"),
  expiryDate: Yup.date().nullable(true),
  //   .required("Required"),
  salesStaffUsers: Yup.array()
    .min(1, "Required")
    .required("Required"),
  deposit: Yup.string().required("Required"),
  inChargeStaffUser: Yup.object()
    .nullable(true)
    .when("type", {
      is: "PACKAGE_PLAN",
      then: Yup.string().required("Required"),
    }),
  totalAmount: Yup.string().when("type", {
    is: "MEMBERSHIP",
    then: Yup.string().required("Required"),
  }),
});

const validate = (values) => {
  const errors = {};

  if (values.effectiveDate && values.expirationDate) {
    if (values.expirationDate.isBefore(values.effectiveDate)) {
      errors.endDate = "Cannot be earlier than effective date";
    }
  }

  return errors;
};

const initialValues = {
  customerUser: "",
  packagePlan: null,
  type: "PACKAGE_PLAN",
  effectiveDate: null,
  expiryDate: null,
  quantity: "",
  salesStaffUsers: [],
  deposit: "",
  totalAmount: "",
  inChargeStaffUser: null,
  active: true,
  fsDone: false,
  remarks: "",
};

const createParams = (values) => {
  const editableField = [
    "customerUser",
    "packagePlan",
    "type",
    "effectiveDate",
    "expiryDate",
    "quantity",
    "salesStaffUsers",
    "deposit",
    "inChargeStaffUser",
    "active",
    "fsDone",
    "remarks",
    "totalAmount",
  ];

  values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
  values.expiryDate = values.expiryDate
    ? moment(values.expiryDate).format("YYYY-MM-DD")
    : null;
  values.packagePlan = values.packagePlan ? values.packagePlan.id : null;
  values.inChargeStaffUser = values.inChargeStaffUser
    ? values.inChargeStaffUser.id
    : null;

  let selectedSales = [];
  values.salesStaffUsers &&
    values.salesStaffUsers.map((item) => selectedSales.push(item.id));
  values.salesStaffUsers = selectedSales;
  values.quantity = values.quantity ? values.quantity : null;

  const params = coreCreateParams(editableField, values);

  return params;
};

export default function(props) {
  const { onOpen, onClose, onSuccess, parent, ...rest } = props;

  const notify = useNotification();
  const notifyApiError = useApiNotification();

  const handleSubmit = (values, formikActions) => {
    onClose();

    const displayProgressTimeoutKey = setTimeout(() => {
      if (values.type === "MEMBERSHIP") {
        notify(`Adding membership"`);
      } else {
        notify(`Adding package plan "${values.packagePlan.name}"`);
      }
    }, PROGRESS_TIME_LIMIT);

    return axios
      .post(`/api/purchasedPackagePlans`, createParams(values))
      .then(() => {
        notify(`Package plan / Membership added`, "success");

        formikActions.resetForm();

        onSuccess();
      })
      .catch((error) => {
        // Show the dialog with error messages if client side error, otherwise notify error messages
        if (error.response.status === 400) {
          formikActions.setErrors(getAllFieldErrors(error.response));

          onOpen();
        }

        notifyApiError(
          error.response.status,
          {
            400: {
              message: `Invalid inputs found`,
              variant: "warning",
            },
            403: {
              message: `Access denied to add package plans / membership`,
              variant: "error",
            },
          },
          {
            message: `Unable to add package plan / membership`,
            variant: "error",
          }
        );
      })
      .finally(() => {
        clearTimeout(displayProgressTimeoutKey);

        formikActions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validate={validate}
    >
      <CreateForm {...rest} parent={parent} onClose={onClose} />
    </Formik>
  );
}
